@import 'utilities/fonts.scss';

.deadline-footer {
	width: 100%;
	//border-top: 1px solid grey;
	background-color: var(--ace-neutral-100);
	color: var(--ace-neutral-600);
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 5px 0;
	.footer-section {
		border-right: solid 1px var(--ace-neutral-300);
		&:last-child {
			border: none;
		}
		.popover {
			max-width: 450px;
			width: 450px;
		}
	}
}

.panel-flat-body {
	.deadline-footer {
		margin-left: 0px;
	}
}

.modal-body {
	.deadline-footer {
		margin-left: 0px;
	}
}

.deadline-popover {
	width: 100%;
	th,
	td {
		vertical-align: top;
		padding: 2px 5px;
	}
	.parcel-year {
		width: 100%;
	}
}

.all-parcels {
	margin-left: 10px;

    > span {
        color: var(--ace-success);
        font-weight: bold;
    }
}

.summary-detail-panel {
	margin-top: 20px;
	.isProjected {
		background-color: var(--not-actual);
		span {
			font-style: italic;
		}
	}
	.summary-detail-table {
		.summary-main-item {
			font-size: 18px;
			padding: 0 10px;
			&:hover {
				background-color: var(--ace-neutral-100);
				cursor: pointer;
			}
		}
		.summary-sub-item {
			font-size: 16px;
			padding-left: 26px;
			padding-right: 10px;
			&:hover {
				background-color: var(--ace-neutral-100);
				cursor: pointer;
			}
		}
		.data-value {
			float: right;
		}
	}
	.summary-total-tax {
		margin-top: 10px;
		font-size: 18px;
		padding: 0 10px;
		.data-value {
			float: right;
		}
		&:hover {
			background-color: var(--ace-neutral-100);
			cursor: pointer;
		}
	}
	.summary-appeal-section {
		cursor: pointer;
		margin-top: 12px;
		padding: 10px;
		a {
			text-decoration: underline;
		}
		&:hover {
			background-color: var(--ace-neutral-100);
		}
	}
}

.analysis-parcel-filter-modal {
    .parcel-filter-modal-body {
        height: 370px;
        overflow-y: auto;

        .status-img {
            height: 25px;
            width: 90px;
            float: right;
            margin-right: 20px;
        }

        table tr td {
            padding: 4px 2px;

            label {
                display: flex;
                align-items: center;

                input {
                    margin: 0;
                }

                span {
                    color: var(--ace-neutral-700);
                    font-weight: normal;
                    font-size: inherit;
                    padding: 0 6px;
                }
            }
        }
    }
}

.portfolio-analysis-chart {
	.highcharts-container {
		margin: auto;
	}
}
