.annual-appeal {
    margin-bottom: 0.5em;
	td {
		padding: 1px 0;
		&:first-child {
			padding-right: 10px;
		}
		&:last-child {
			font-weight: normal;
		}
	}
	.form-control {
		transition: intial;
		// text-align: right;
		// display: inline-block;
		width: auto;
	}
	.hearing-date {
		p.input-group {
			width: 100%;
			input {
				width: 100%;
			}
		}
	}
	.savings {
		.overridden {
			color: var(--ace-danger);
		}
		.estimated {
			background-color: var(--not-actual);
		}
	}
	.input-number {
		text-align: right;
	}
}

.new-appeal {
	cursor: pointer;
}

.new-appeal-disabled {
	background-color: grey;
	cursor: not-allowed;
}

.appeal-deadline-assessor-exceptions-dialog {
	.modal-dialog {
		height: 82vh;
		.modal-content {
			overflow-y: auto;
			overflow-x: hidden;
			height: 80vh;
		}
	}
}

.workflow-state-assessor-exceptions-dialog {
	.modal-dialog {
		height: 82vh;
		.modal-content {
			overflow-y: auto;
			overflow-x: hidden;
			height: 80vh;
		}
	}
}


table.assessor-exceptions {
	thead,
	tbody,
	tr,
	td,
	th {
		display: block;
	}
	thead {
		width: 87%;
		tr {
			th {
				border-bottom: none;
				float: left;
			}
		}
	}
	tbody {
		/*height: 230px;*/
		overflow-y: auto;
		width: 90%;
		tr {
			td {
				border-top: none;
				float: left;
				padding: 4px;
				margin-left: 5px;
			}
		}
	}
}



