/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * bebas-neue:
 *   - http://typekit.com/eulas/000000000000000077359f59
 * myriad-pro:
 *   - http://typekit.com/eulas/00000000000000000001709e
 *   - http://typekit.com/eulas/00000000000000000001709f
 *   - http://typekit.com/eulas/00000000000000000001709b
 *   - http://typekit.com/eulas/00000000000000000001709a
 *   - http://typekit.com/eulas/00000000000000000001709c
 *   - http://typekit.com/eulas/00000000000000000001709d
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-07-15 14:41:21 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=eel2xjx&ht=tk&f=6846.6847.6848.6851.6852.6853.13407&a=9684963&app=typekit&e=css');

@font-face {
    font-family: 'myriad-pro';
    src: url('https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('woff'),
        url('https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'myriad-pro';
    src: url('https://use.typekit.net/af/2e2357/00000000000000000001709f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/2e2357/00000000000000000001709f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
            format('woff'),
        url('https://use.typekit.net/af/2e2357/00000000000000000001709f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
            format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'myriad-pro';
    src: url('https://use.typekit.net/af/d32e26/00000000000000000001709b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/d32e26/00000000000000000001709b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/d32e26/00000000000000000001709b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
            format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'myriad-pro';
    src: url('https://use.typekit.net/af/cafa63/00000000000000000001709a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/cafa63/00000000000000000001709a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/cafa63/00000000000000000001709a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'myriad-pro';
    src: url('https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
            format('woff'),
        url('https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'myriad-pro';
    src: url('https://use.typekit.net/af/d50a1b/00000000000000000001709d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/d50a1b/00000000000000000001709d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
            format('woff'),
        url('https://use.typekit.net/af/d50a1b/00000000000000000001709d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
            format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: 'bebas-neue';
    src: url('https://use.typekit.net/af/f9d226/000000000000000077359f59/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/f9d226/000000000000000077359f59/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/f9d226/000000000000000077359f59/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}
