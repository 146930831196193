form[name="parcelForm"] .title {
    display: inline-block;
    vertical-align: top;
    min-width: 135px;
    font-weight: bold;
}

form[name="parcelForm"] .editable {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
}

form[name="parcelForm"] .panel-flat-body {
    width: 100%;
}

.parcel-pager {
    padding-right: 12px;
    margin: 12px;
}

.parcel-info-fields {
    .info-fields-form-group {
        padding-bottom: 5px;
    }

    .standard-field {
        width: 288px;
    }

    .parcel-title {
        margin-bottom: 5px;
    }

    .property-type-label {
        display: inline-block;
        margin: 0;
    }

    .acct-num {
        margin: 0;
        max-width: initial;
        padding-top: 3px !important;
        padding-left: 5px;
    }

    .acct-num-to-add {
        .form-control {
            display: inline-block;
            width: 120px;
        }
    }

    .parcel-images {
        position: absolute;
        top: 20px;
        right: 20px;

        td {
            img {
                margin: auto;
                max-height: 60px;
            }
        }
    }
}

.activity-summary-panel {
    .activity-summary-row {
        border-bottom: var(--ace-neutral-300) solid 1px;

        .overridden {
            color: var(--ace-danger);
        }

        td {
            padding: 6px 4px;
        }

        &:hover {
            background-color: var(--ace-neutral-100);
        }

        &:first-child {
            border-top: none;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.parcel-linkage-modal {
    width: 100%;

    td {
        padding: 8px;

        &:first-child {
            font-weight: bold;
            vertical-align: top;
        }

        .checkbox {
            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.panel-maximized {
    .panel-container {
        display: none;
    }

    div.current-max-panel {
        display: block;
    }

    div.current-max-panel-margin {
        display: block;
        margin: 15px 0;

        .panel-flat-body {
            height: unset;
        }
    }

    div.current-max-panel-flex {
        display: flex;
        margin: 15px 0;

        .panel-flat-body {
            height: unset;
        }
    }

    div.panel-flat-body {
        height: 640px;
    }

    div.tax-bills {
        height: 595px;
    }

    .budget-details {
        .card-body {
            max-height: initial !important;
        }
    }
}

.preserve-white-space {
    white-space: pre;
}

/* Parcel collector and bill cluster tax rate setup styles (maybe these should be in component scss instead?) */
.tax-rate-setup {
    + .modal-body {
        padding: 35px 15px 5px 15px;
    }

    + .modal-footer {
        text-align: left;
        justify-content: space-between;
    }

    .loading-all {
        margin-top: 50px;
    }

    .content-container {
        margin: 1em;
    }

    .top-actions {
        label {
            display: inline-block;
            margin-bottom: 1em;
        }

        input[type='text'] {
            width: 15em;
            display: inline-block;
            margin-left: 0.5em;
        }

        .clear-tax-rate-container {
            display: inline-block;

            button {
                height: 25px;
            }
        }

        button.search {
            float: right;
        }
    }

    .year-label {
        float: right;

        select {
            width: 6em;
            display: inline-block;
            margin-left: 0.5em;
            font-weight: normal;
        }
    }

    .table-container {
        max-height: 35em;
        overflow-y: auto;
        clear: both;
    }

    table.tax-rate-setup {
        border-collapse: separate;
        width: 100%;

        tr button {
            visibility: hidden;
        }

        tr:hover {
            &:not(.no-hover) {
                background-color: var(--ace-neutral-100);
            }

            button {
                visibility: visible;
            }
        }

        tr.summary-row {
            td {
                padding: 0 0 0 10px;
            }

            td.total-rate {
                padding: 0 0 0 10px;
            }

            td.total-label {
                border-top: 1px solid white;
                padding-right: 0.5em;
            }

            th.processing-action, td.processing-action {
                padding: 0;
            }
        }

        th.right-align, td.right-align {
            text-align: right;
            padding: 3px 0 3px 6px;
        }

        td, th {
            padding: 3px;
        }

        th.processing-action, td.processing-action {
            padding: 0;
        }

        .summary-spacer {
            td {
                padding: 0;
                height: 3px;
            }
        }

        .grouping-spacer {
            td {
                height: 5px;
            }
        }

        .estimated {
            background-color: var(--not-actual-emphasis)
        }

        .pending {
            background-color: var(--ace-focused-color)
        }

        .remove-authority {
            height: 22px;
            width: 22px;
            margin: 0;
        }

        .total-label {
            text-align: right;
        }

        .total-rate {
            border-top: 1px solid black;
            text-align: right;
        }
    }

    .adding-authority {
        select {
            min-width: 5em;
        }
    }

    .group-by-category {
        label {
            font-weight: normal;
        }
    }

    .action-buttons {
        position: absolute;
        right: 15px;
        bottom: 15px;

        div {
            display: inline-block;
        }

        select {
            margin-right: 0.5em;
        }
    }

    select.new-tax-auth {
        max-width: 20em;
    }

    li a.dropdown-item {
        cursor: pointer;
    }

    div.first-encounter {
        label.radio label {
            display: inline-block;
            margin-left: 0.5em;
        }

        label.radio label:first-child {
            margin-left: 0;
        }

        label {
            display: block;

            span {
                font-weight: normal;
            }

            span:first-child {
                display: inline-block;
                width: 7em;
                font-weight: bold;
                text-align: right;
                margin-right: 0.5em;
            }
        }
    }
}

