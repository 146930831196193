appeal-application {
    height: 100%;
    display: flex;
    flex-flow: column;

    .tab-content {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;

        tab {
            flex: 1 1 auto;
        }
    }
}

appeal-application-set-page-modal {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    label > input[type="radio"] {
        margin: 0;
        vertical-align: middle;
    }

    label > span {
        margin-left: 0.25em;
        font-size: 15px;
        vertical-align: middle;
        text-transform: none;
    }
}
