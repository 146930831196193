@mixin dropdown-menu-shift( $num-pixels, $arrow-position: 10px ) {
	left: $num-pixels !important;
	&:before { left: -$num-pixels + $arrow-position !important; }
	&:after  { left: -$num-pixels + $arrow-position + 1px !important; }
}

.dropdown-submenu {
	//http://stackoverflow.com/questions/18023493/bootstrap-3-dropdown-sub-menu-missing
	position: relative;
}

.dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px 6px;
	-moz-border-radius: 0 6px 6px 6px;
	border-radius: 0 6px 6px 6px;
	padding-right: 20px;

	&.left-menu {
		@include dropdown-menu-shift(-210px);
		&.wide-menu {
			@include dropdown-menu-shift(-280px);
		}

		a{
			white-space: normal !important;
			width:190px;

			&.wide-menu {
				width:260px;
			}
		}

	}

}

.dropdown-submenu:hover>.dropdown-menu, .dropdown-submenu-open {
	display: block;
}

.dropdown-submenu>a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: var(--ace-neutral-300);
	margin-top: 5px;
	margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
	border-left-color: var(--ace-neutral-white);
}

.dropdown-submenu.float-start {
	float: none !important;
}

.dropdown-submenu.float-start>.dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}


.dropdown.pinned, .pinned > .dropdown-menu, .dropdown-menu.pinned {
	display: block !important;
}

//WK-4436 - fixing a bug where dropdowns at the bottom of panels extend over the edge
//This is so our statically-placed custom submenus know to grow up instead of down
.dropup {
	.dropdown-submenu>.dropdown-menu {
		top: auto;
		bottom: -10px;
	}
}
