form[name="siteForm"] .title {
	display: inline-block;
	padding-top: 5px;
	vertical-align: top;
	min-width: 90px;
	font-weight: bold;
}

form[name="siteForm"] .editable {
	display: inline-block;
	padding-top: 5px;
	vertical-align: top;
}

.site-images {
	width: 100%;
	margin-top: 20px;
	td {
		img {
			margin: auto;
			max-height: 60px;
			cursor: pointer;
		}
	}
}

.site-parcel-list-panel-height {
	.tax-is-estimated,
	.not-actual {
		background-color: var(--not-actual-emphasis);
	}

	.increase {
        --ace-action: #5373b4;
		color: var(--ace-action);
	}
	.decrease {
		color: var(--ace-success);
	}

	.table-inactive {
		position: absolute;
		opacity: 0.35;
		width: 75px;
		left: 0;
		transform: rotate(-12deg);
	}
}

.site-parcel-row {
    min-height: 490px;
}

.site-parcel-list-panel {
    flex: 1 0 75%;
    order: 2;

    .tax-is-estimated,
    .not-actual {
        background-color: var(--not-actual-emphasis);
    }

    .increase {
        --ace-action: #337ab7;
        color: var(--ace-action);
    }

    .decrease {
        color: var(--ace-success);
    }

    .table-inactive {
        position: absolute;
        opacity: 0.35;
        width: 75px;
        left: 0;
        transform: rotate(-12deg);
    }
}

.site-characteristics-panel {
    flex: 1 0 25%;
    order: 2;
}

.wrapper {
	margin: 0 auto;
}

.pdf-controls {
	width: 100%;
	display: block;
	background: var(--ace-neutral-100);
}

.rotate0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
}

.rotate90 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.rotate180 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.rotate270 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}


iframe {
	position: relative;
}


.sold-img {
	width: 20%;
	transform: rotate(330deg);
	right: 50px;
	position: absolute;
	opacity: 0.5;
	margin-top: 99px;
}

.toast-warning {
    background-color: var(--ace-warning);
}

#digestDebugDiv {
    display:none;
}

.digest-debug #digestDebugDiv {
    display:block;
}

.specialist-list-item {
	margin-top: 10px;
}

.map-site-address {
    address {
        margin-bottom: 0;
    }
}

.valuation-panel,
.payment-batch-details,
.accruals-panel {
	.tab-container {
		display: flex;
		flex-flow: column;
		height: 100%;

		.tab-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            // overflow: hidden;

			.tab-pane {
				flex: 1 1 auto;
                height: 100%;
			}
		}
	}
}
