.local-display-mode {
    border-top: solid 4px var(--ace-action);
}

.non-prod-display-mode {
    border-top: solid 4px var(--ace-tertiary-color);
}

.uat-display-mode {
    border-top: solid 4px var(--ace-danger);
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
ul[dnd-list] {
	min-height: 22px;
	padding-left: 0px;
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
ul[dnd-list] .dndDraggingSource {
	display: none;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
ul[dnd-list] .dndPlaceholder {
	display: block;
	background-color: var(--ace-neutral-200);
	min-height: 22px;
}

/**
 * The dnd-lists's child elements currently MUST have
 * position: relative. Otherwise we can not determine
 * whether the mouse pointer is in the upper or lower
 * half of the element we are dragging over. In other
 * browsers we can use event.offsetY for this.

ul[dnd-list] li {
	background-color: var(--ace-neutral-white);
	border: 1px solid var(--ace-neutral-200);
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
}*/

/**
 * Show selected elements in green
 */
ul[dnd-list] li.selected {
	background-color: var(--ace-success-ghosted);
	color: var(--ace-success);
}
