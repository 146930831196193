@import "utilities/mixins.scss";


.report-status-panel {
	height: 56px;
	background-color: var(--ace-neutral-white);
	border-bottom: solid 1px var(--ace-neutral-300);
	box-shadow: var(--ace-shadow);
}
.report-status-summary {
	min-width: 120px;
	display: inline-block;
	height: 40px;
	margin-left: 10px;
	margin-top: 7px;
	border-radius: 5px;
	box-shadow: var(--ace-shadow);
	background-color: var(--ace-neutral-100);
	padding: 2px 6px;
	font-size: 12px;
	border: solid 1px var(--ace-neutral-300);
}

.report-icon {
	width: 44px;
	height: 40px;
	float: left;
	font-size: 36px;
	margin-top: -2px;
	margin-right: 10px;
	position: relative;
	margin-left: -6px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;

	.completed-report-icon {
		background-color: var(--under-appeal);
	}

	&.error-report-icon {
		height: 38px;
		font-size: 30px;
		background-color: var(--ace-danger);
	}
}

.completed-report {
	background-color: var(--ace-success);
	color: white;
}

.error-report {
	background-color: var(--ace-danger);
	color: white;
}

.reportSlideDown.ng-hide {
	height:0;
}
.reportSlideDown {
	transition: .5s linear all;
	height: 56px;
	overflow: hidden;
}

.select-entity {
	.entity-panel {
		height: 220px;
	}
	.small-header {
		padding-right: 5px;
	}

	.loading-list {
		opacity: 0.5;
	}

	.entity-list {
		width: 100%;
		height: 150px;

		@include multi-select;
	}

	.entity-dropdown {
		margin-top: -4px;
		padding: 3px 10px;
		width: auto;
		display: inline-block;
		height: 25px;
	}


	.additional-selections {
		div {
			margin: 5px 0;

			button {
				width: 100%;
				padding: 7px 10px;
			}
		}
	}
}

.report-manager-year-list {
	option {
		&:disabled {
			display: none;
		}
	}
}

.further-limit-budget {
	.form-group {
		margin-right: 35px;

		label {
			margin-right: 5px;
		}
	}
}

.format-output-budget {
	.summary-report-options {
		.checkbox {
			margin-left: 10px;
		}

		.order-and-total-by {
			height: 228px;
			option:checked {
				box-shadow: 0 0 10px 10px var(ace-info-ghosted) inset;
			}
		}
	}
}

.detail-report-options {
	> div {
		padding: 5px 0;
	}

	select {
		width: auto;
		display: inline-block;
	}

	.select-label {
		min-width: 190px;
		text-align: right;
		margin-right: 5px;
	}
}

.format-psr {
	select {
		&:not(.order-by) {
			width: auto;
			display: inline-block;
		}
	}

	.form-group {
		label {
			text-align: right;
			margin-right: 5px;
		}
	}

	.psr-output-col1 {
		.form-group {
			label {
				min-width: 122px;
			}
		}
	}

	.psr-output-col2 {
		label {
			min-width: 120px;
		}
	}
}

.progress-status-form,
.further-limit-budget {
	.form-group {
		vertical-align: top;
	}
}

.format-schedule-summary {
    .first-column {
        text-align: right;
        padding-right: 5px;
    }
}

.further-limit-schedule-summary {
    div.checkbox {
        padding-top: 0;
    }
}

.further-limit-asset-detail {
    div.checkbox {
        padding-top: 0;
    }

    .xl {
        width: 1125px;
    }
}

.format-asset-detail {
    .xl {
        width: 1125px;
    }

    .first-column {
        text-align: right;
        padding-right: 5px;
    }
}
