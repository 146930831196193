.attachment-list-animation.ng-hide-add,
.attachment-list-animation.ng-hide-remove {
	-webkit-transition: 0.3s;
	transition: 0.3s;
	// display: block !important;
	width: 33%;
}

.attachment-list-animation.ng-hide {
	width: 0;
}

$attachment-list-border: solid 1pt var(--ace-neutral-200);
$largest-list-height: 73vh;
.attachment-list {
	overflow-x: hidden;
	white-space: nowrap;
	border-right: $attachment-list-border;
	.list-group {
		height: $largest-list-height;
		margin-top: 20px;
		overflow-y: auto;
		overflow-x: hidden;
		margin-bottom: 0;
	}
	.list-border {
		border: $attachment-list-border;
	}
}

.attachment-list-collapsed {
	width: 0;
}

$largest-viewer-height: 79vh;
.attachment-viewer {
	.preview-window {
		width: 93.5%;
		margin-left: 29px;
		padding: 0;
		height: $largest-viewer-height;
	}
	.toggle-list {
		position: absolute;
		bottom: 0;
		left: 10px;
		z-index: 2;
	}
}

@media (max-height: 930px) {
	.attachment-list {
		.list-group {
			height: $largest-list-height - 3vh;
		}
	}
	.attachment-viewer {
		.preview-window {
			height: $largest-viewer-height - 3vh;
		}
	}
}

@media (max-height: 800px) {
	.attachment-list {
		.list-group {
			height: $largest-list-height - 5vh;
		}
	}
	.attachment-viewer {
		.preview-window {
			height: $largest-viewer-height - 5vh;
		}
	}
}

@media (max-height: 700px) {
	.attachment-list {
		.list-group {
			height: $largest-list-height - 7vh;
		}
	}
	.attachment-viewer {
		.preview-window {
			height: $largest-viewer-height - 7vh;
		}
	}
}

.attachment-list-buttons {
	margin-top: 10px;
	margin-left: 0px;
}

.preview-nav {
	.fa {
		position: absolute;
		top: 50%;
		cursor: pointer;
		color: grey;
		&:hover {
			color: initial;
		}
	}
	.fa-arrow-right {
		right: 0;
	}
}

.preview-nav.ng-hide-add {
	-webkit-transition: 0.5s;
	transition: 0.5s;
	/* remember to add this */
	display: block!important;
	opacity: 1;
}

.preview-nav.ng-hide {
	opacity: 0;
}

.modal-spinner {
	margin-left: 250px;
	position: absolute;
	z-index: 999;
}

.loading-data {
	opacity: .4;
}

.file-dragged {
	position: absolute;
	top: 0;
	width: 98%;
	height: 300px;
	border: 4px dashed green;
	text-align: center;
	background-color: var(--ace-neutral-500);
	background-image: url("/images/45-degree-fabric-light.png");
	font-size: 0pt;
	left: 9px;
	background-clip: padding-box;
}

.attachment-tooltip {
	overflow-x: hidden;
}

.payment-stub {
	.preview-window {
        width: 96.5%;
        margin-left: 25px;
        padding: 0;
        //height: 672px;
        //height: 80vh;
        .preview-window-image {
            max-height: 79vh;
            margin: auto;
        }
    }
}
