@import 'utilities/fonts.scss';
@import 'utilities/mixins.scss';

$criteria-height: 259px;
.advanced-criteria {
	.panel-flat-body {
		min-height: $criteria-height !important;
		display: table;
		width: 100%;
		.add-text {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

.output-types {
    --ace-action: #5373b4;
    --ace-action-hover: #3a5a9b;
	.btn {
		background-color: var(--ace-action);
		border-color: var(--ace-action-hover);
	}
	.btn.active {
		background-color: var(--ace-action-hover);
	}
}

.as-header {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    h1.as-title {
        margin: 0;
        font-family: $header-font;
    }
    .as-header-functions {
        border: solid 1px var(--ace-neutral-300);
        box-shadow: var(--ace-shadow);
        padding: 5px 20px;
        background-color: white;
        border-radius: 8px;
        label {
            margin-right: 20px;
        }
        > div {
            display: inline-block;
            vertical-align: middle;
        }

        .has-icon, .btn-sm {
            @include icon-only-button;
        }
    }
}

.as-add-columns-modal {
	.modal-dialog {
		width: 1100px;
	}
	.modal-header {
		padding: 5px 15px;
	}
	a.accordion-toggle {
		&:focus {
			outline: none;
		}
	}
	.panel-collapse {
		.card-body {
			padding: 5px 15px;
		}
	}
	.panel-group {
		margin-bottom: 10px;
	}
	.column-category-primary {
		border: solid 1px var(--ace-neutral-300);
		> .panel-heading {
			background-color: var(--ace-neutral-300);
			color: var(--ace-neutral-700);

			.has-icon, i{
				//important tag because bootstrap keeps trying to override it
				color: var(--ace-neutral-700) !important;
			}
		}
	}
	.column-category-secondary {
		border: solid 1px var(--ace-neutral-300);
		> .panel-heading {
			background-color: var(--ace-neutral-100);
		}
	}
	i.fa {
		padding-right: 5px;
	}
	.as-subcategory {
		margin-top: 10px;
		.panel-heading {
			padding: 5px 15px;
			.panel-title {
				font-size: 14px;
			}
		}
	}
	.nested-tabs {
		height: 30px;
		.nav-link {
			padding: 4px 15px;
		}
	}
	.as-columns-container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
		align-content: flex-start;
		height: 250px;
		overflow-y: auto;
		row-gap: 5px;
		column-gap: 10px;
		.checkbox {
			margin-bottom: 0;
			margin-top: 0;
			white-space: nowrap;
			label {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

.favorite-star {
	color: var(--ace-tertiary-color);
	font-size: 26px;
	cursor: pointer;
	margin-right: 10px;
}

.filter-checkboxes {
	.checkbox {
		label {
			display: inline-flex;
			min-width: 150px;
		}
	}
}
