form[name="userSetupForm"] .title {
	display: inline-block;
	padding-top: 5px;
	vertical-align: top;
	min-width: 90px;
	font-weight: bold;
}

form[name="userSetupForm"] .normal-text {
	display: inline-block;
	padding-top: 5px;
	vertical-align: top;
	min-width: 90px;
}

form[name="userSetupForm"] .editable {
	display: inline-block;
	padding-top: 5px;
	vertical-align: top;
}

span.user-setup-button-align-fix>button.btn {
    margin-top: 8px;
}

.application-permission-label {
	min-width: 35px;
	display: inline-block;
	margin-left: 14px;
}
