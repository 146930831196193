.address-tooltip .tooltip-inner {
	text-align: left;
}

div.quick-search-state{
	margin: 5px;
	padding:2px;

	a{
		display: block;
	}
}
div.quick-search-state-container{
	margin-bottom: 20px;
}

div.quick-search-state:hover{
	background: var(--ace-neutral-200);
	color: var(--ace-info-hover);
	font-weight: bold;
}

div.setting{
    padding: 10px 16px;
    border: 1px solid var(--ace-neutral-300);
    border-radius: 6px;
    width: 400px;
    margin-top: 8px;
}

.add-year-progress {
	li {
		margin: 10px;
	}
}

.company-in-use-characteristics {
	margin-top: 15px;
	display: flex;
	justify-content: space-evenly;
	align-items: baseline;

	.form-group {
		margin-bottom: 0;
	}
}

accruals-descriptor-selector {
	.dropdown-header {
		color: initial;
		font-weight: bold;
	}
}
