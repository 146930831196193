@import "utilities/fonts.scss";

.action-view-overview-controls {
	text-align: center;
}

.my-tasks-criteria {
	.form-group {
		.radio, .checkbox {
			margin-left: 10px;
		}
		.first-radio {
			margin-top: 0;
		}
	}

	.date-time-panel {

		.input-inline {
			width: 45px;
		}

		.form-group {
			padding-bottom: 20px;
			padding-top: 20px;
			margin-top: 0;
			margin-bottom: 0;
			border-bottom: 1px dashed lightgrey;
		}
		label {
			width: 99px;
		}

		.when {
			padding-top: 0;
			label {
				width: 95px;
			}
		}
		select, input {
			display: inline-block;
		}

		select {
			width: auto;
		}

		.date-range {
			display: inline-block;
			vertical-align: top;
			weiss-datepicker {
				display: inline-block;
				vertical-align: middle;
				width: 129px;
			}

			div {
				text-align: center;
				margin: 5px 0;
			}
		}
	}
}

.action-view-overview {
	.first-grouping-header {
		text-align: left !important;
		font-family: $header-font;
		font-size: 16px;
	}

	.second-grouping-header {
		text-align: left !important;
		padding-left: 30px;
		width: 260px;
		min-width: 260px;
		max-width: 260px;
		padding-right: 20px;
	}

	.no-grouping-header {
		font-family: $header-font;
		font-size: 16px;
		cursor: pointer;
		text-align: left !important;
	}

	.task-table {
		th {
			text-align: center;
			width: 75px;

			&.name-header {
				width: 200px;
				text-align: left;
			}

			&.two-grouping-header {
				position: relative;
				top: 36px;
			}
		}

		td {

			text-align: center;
			border: solid var(--ace-neutral-white) 2px;

			min-width: 125px;
			height: 36px;

			span {
				display: inline-block;
				text-align: left;
			}

			&.urgency-overdue {
				background-color: var(--ace-neutral-600);
				color: var(--ace-neutral-white);
			}

			&.urgency-critical {
				//background-color: var(--ace-danger-ghosted);
				background-color: #EF5350;
				color: var(--ace-neutral-white);
			}

			&.urgency-imminent {
				//background-color: var(--ace-warning-ghosted);
				background-color: #FFF59D;
			}

			&.urgency-approaching {
				//background-color: var(--ace-success-ghosted);
                background-color: #81C784;
			}

			&.urgency-prospective {
				background-color: var(--ace-neutral-200);
			}

			&.misc-background {
				background-color: var(--ace-neutral-100);
			}

			&.urgency-name {
				min-width: 240px;
				width: 240px;
				text-align: left;
				span {
					margin-left: 20px;
					text-align: left;
				}
			}

			.value-badge {
				width: 60px;
				background-color: rgba(255, 255, 255, 0.85);
				display: inline-block;
				border-radius: 6px;
				color: var(--ace-neutral-800);
				box-shadow: 1px 1px 4px var(--ace-neutral-700);
				text-align:center;
				cursor: pointer;
			}

			.wide-value-badge {
				width: 80px;
				background-color: rgba(255, 255, 255, 0.85);
				display: inline-block;
				border-radius: 6px;
				color: var(--ace-neutral-800);
				box-shadow: 1px 1px 4px var(--ace-neutral-700);
				text-align:center;
				cursor: pointer;
			}

		}

		.task-table-grouping::after {
			content: "-";
			color: transparent;
			line-height: 30px;
		}
	}

	.task-series-header {

		font-family: 'Oswald';
		font-weight: bold;
		font-size: 22px;

		td {
			border: none;
			text-align: left;

			span {
				position: absolute;
			}
		}
	}




}

ul.bulk-warning-list,ul.bulk-error-list {
    margin:1em;
    >li {
        list-style-type: none
    }
}

/* Note: If this changes, be sure to also update SD.AgGrid.Directive.js (the getRowStyle function) */
ul.bulk-warning-list>li {
    color: var(--ace-warning);
    font-weight: bold;
}

ul.bulk-error-list>li {
    color: var(--ace-danger);
}

.category-columns-wrapper {
	max-height: 325px;
	overflow-y: auto;
	padding: 15px;
}
