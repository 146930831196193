/** BEGIN: File Drop specific styles. **/

.drop-zone {
    margin: auto;
    height: 100px;
    border: 2px dotted var(--ace-focused-color);
    border-radius: 30px;
}

.file-drop-content {
    --ace-action: #5373b4;
    color: var(--ace-action);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.over {
    background-color: rgba(147, 147, 147, 0.5);
}

/** END: File Drop specific styles. **/

ws-file-picker {
    display: block;

    .cwrap {
        display: flex;
        flex-flow: column;

        .ws-file-picker {
            padding: 0;
            margin: 0;
            border: 1px dashed var(--ace-neutral-300);
            visibility: hidden;
            height: 1px;
            width: 1px;
        }

        .ws-file-drop-zone {
            overflow: hidden;

            .drop-zone {
                display: block;
                border: 1px dashed var(--ace-neutral-800);
                border-radius: 10px;

                &.over {
                    background-color: var(--ace-neutral-300);

                    .file-drop-content {
                        color: var(--ace-neutral-800);
                    }
                }

                .file-drop-content {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;
                    color: var(--ace-neutral-800);

                    .select-file-prompt {
                        flex: none;
                        display: flex;
                        flex-flow: row;
                        align-items: center;
                    }

                    .selected-file-name {
                        flex: none;
                        font-style: italic;
                        padding-left: 8px;
                    }
                }
            }
        }
    }
}
