$spacing: 14px;

.resizable {
    position: relative;
    &.no-transition {
        transition: none !important;
    }
}
.rg-right, .rg-left, .rg-top, .rg-bottom {
    display: block;
    width: $spacing; height: $spacing; line-height: $spacing;
    position: absolute;
    z-index: 1;
    user-select: none;
    span {
        position: absolute;
        box-sizing: border-box;
        display: block;
        border: 1px solid var(--ace-neutral-300);
    }
}
.rg-right, .rg-left {
    span {
        border-width: 0 1px;
        top: 50%; margin-top: -10px;
        margin: -10px 0 0 calc($spacing/4);
        height: 20px;
        width: calc($spacing/2);
    }
}
.rg-top, .rg-bottom {
    span {
        border-width: 1px 0;
        left: 50%;
        margin: calc($spacing/4) 0 0 -10px;
        width: 20px;
        height: calc($spacing/2);
    }
}
.rg-top     {
    cursor: row-resize;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: calc(0px - $spacing/2);
}
.rg-right   {
    cursor: col-resize;
    height: 100%;
    right: 0;
    top: 0;
    margin-right: -$spacing;
}
.rg-bottom  {
    cursor: row-resize;
    width: 100%;
    bottom: 0;
    left: 0;
    margin-bottom: calc(0px - $spacing/2);
}
.rg-left    {
    cursor: col-resize;
    height: 100%;
    left: 0;
    top: 0;
    margin-left: -$spacing;
}
