//Dima: scrollable table, implemented based on samples from http://salzerdesign.com/test/fixedTable.html

.fixed-table-container {
    width: 100%;
    height: 250px;
    margin: 10px auto;
    /* above is decorative or flexible */
    position: relative; /* could be absolute or relative */
    padding-top: 30px; /* height of header */
}

.fixed-table-container-inner {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    table {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        thead{
            tr{
                th, td {
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }

                th{
                    border-bottom: 0px !important;
                }
            }
        }

        tbody{
            tr{
                border-top: 0px !important;
            }
        }
    }
}

.header-background {
    height: 30px; /* height of header */
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-bottom: 2px solid var(--ace-neutral-200);
}


.th-inner {
    position: absolute;
    top: 0;
    line-height: 30px; /* height of header */
    text-align: left;
    padding-left: 5px;
    margin-left: -5px;
}

.hidden-head {
    min-width: 530px; /* enough width to show all header text, or bad things happen */
}

.hidden-header .th-inner {
    position: static;
    overflow-y: hidden;
    height: 0;
    white-space: nowrap;
    padding: 0px !important;
}

.hidden-header{
    th {
        border-bottom: 0px !important;
        border-top: 0px !important;
        padding: 0px !important;
    }
}
