@import '../compliance/compliance-variables.scss';

.blank {
}

cost-adjustment-tooltip ul {
    padding-left: 16px;
}

asset-list {
    display: flex;
    flex-flow: column;
    height: 100%;
    max-height: 100%;
    width: 100%;

    & > div {
        max-height: 100%
    }

    .grid-tools-panel {
        margin-bottom: 16px;
        overflow: auto;
    }

    .ws-section__filters__filter-group {
        label {
            white-space: nowrap;
        }
    }

    weissman-datetime {
        display: flex;

        .form-control {
            display: flex;
        }

        .input-group-addon {
            display: flex;
            border: none;
            background: none;
            padding-right: 0;
            padding-left: 0;
            margin-right: 0;
            margin: 0;
        }
    }

    asset-list-actions-header {
        button.ace-btn {
            margin-top: 3px;
        }
    }

    .grid-wrapper {
        margin-bottom: 8px;

        .ag-layout-normal .ag-floating-bottom {
            position: static;

            .ag-row {
                background: var(--ace-neutral-100);
            }

            .totals-row, .selected-totals-row {
                transform: none !important;

                .ag-cell.ag-cell-with-height.ag-cell-value:empty {
                    background: var(--ace-neutral-100);

                    &::after {
                        content: '-';
                    }
                }
            }

            .totals-row {
                .ag-cell.ag-cell-with-height.ag-cell-value:not(:empty) {
                    background: var(--ace-neutral-100);
                }
            }

            .ag-floating-bottom-viewport {
                .selected-totals-row {
                    .ag-cell.ag-cell-with-height.ag-cell-value:not(:empty) {
                        background: var(--ace-neutral-200);
                    }
                }
            }
        }
    }

    .ag-theme-balham .cell-validation-error {
        border-left-style: solid;
        border-left-color: var(--ace-danger);
        border-left-width: 8px;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
    }
}


.totals-panel {
    padding: 4px 4px 16px 0;
    font-weight: bold;
    font-size: 0.9em;

    .asset-total-outer {
        display: inline-block;
        border: 1px solid var(--ace-neutral-300);
        border-left: none;
        background: var(ace-warning-ghosted);

        .asset-grid-totals {
            display: inline-block;
            font-weight: bold;
            font-size: .8em;
            border-left: 1px solid var(--ace-neutral-300);

            .asset-total-header {
                min-width: 80px;
                border-bottom: 1px solid var(--ace-neutral-300);
                text-align: center;
            }

            .asset-total-value, .asset-total-selected-value {
                padding-left: 10px;
                text-align: right;
                padding-right: 4px;
            }


            .asset-total-selected-value {
                &.is-loading {
                    opacity: 0.5;
                }
            }
        }
    }
}

.totals-label {
    display: inline-block;
    padding-right: 8px;
    vertical-align: top;
    font-size: small;
    font-weight: bold;
}

.reporting-assets-outer {
    max-width: 100%;
    overflow-x: auto;
    display: inline-block;
    flex: 1 1 auto;
}

.reporting-assets-holder {
    display: flex;
    flex-direction: row;
}


.reporting-asset-holder {
    display: inline-block;
    flex: 1 0 auto;

    form {
        width: 100%;
    }
}

.asset-source-form-holder {
    flex: 0 0 auto;
    overflow: hidden;
}

.asset-info-holder {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--ace-neutral-200);
}

asset-info-cost-editor {
    .asset-name, .asset-percent {
        display: inline-block;
    }

    .asset-name {
        width: 60%;
    }

    .asset-percent-edit-row {
        margin-bottom: 6px;

        .input-group input.form-control {
            width: 78px;
        }
    }

    .asset-info-cost-status {
        margin-bottom: 20px;
        background: var(--ace-neutral-100);
        padding: 4px;
    }

    .asset-cost-header-line {
        display: flex;
        border-bottom: 1px solid var(--ace-neutral-200);
        margin-bottom: 6px;
    }

    .asset-percent-edit-row {
        line-height: 28px;
    }

    .cost-amount {
        --ace-action: #5373b4;
        margin-left: 20px;
        width: 100px;
        color: var(--ace-action);
        text-align: right;
    }

    .unattributed-percent {
        float: right;
    }
}

asset-info {
    --ace-action: #5373b4;
    font-size: 0.9em;

    .asset-section-hidden {
        display: none;
    }

    .leasing-field-selector {
        ul.nav li {
            margin-bottom: -3px;
        }
    }

    .clear-override-cell {
        vertical-align: middle !important;
    }

    .clear-override-button {
        margin: 0 !important;
    }

    .never-imported-asset {
        .overridden {
            color: var(--ace-neutral-800);
        }
    }

    tr.parcel-information-row {
        border-top: 2px solid var(--ace-neutral-200);
        padding-top: 6px;
        display: table-row;

        td {
            padding-top: 14px;

            &:first-child {
                font-weight: bold;
            }
        }
    }

    tr.parcel-override-row {
        td {
            &:first-child {
                color: var(ace-neutral-400);
            }

            color: var(ace-neutral-400);
        }

        .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
            background-color: white;
        }

        .form-control {
            width: 300px;
        }
    }

    tr.parcel-override-row.parcel-override-active {
        td {
            &:first-child {
                color: var(--ace-danger);
            }
        }
    }

    .alternative-cost-value {
        color: var(--ace-action);
    }

    .alternative-date-value {
        color: var(--ace-action);
    }

    .has-adjustments {
        color: var(--ace-action);
    }

    .alternate-date-field-arrow, .alternate-cost-field-arrow {
        position: absolute;

        svg {
            stroke: var(--ace-action);
            stroke-width: 4
        }
    }

    .alt-field-holder {
        display: inline-block;
    }

    .arrow-helper {
        position: absolute;
        left: 0;
        top: 0;
    }

    .asset-info-table-edit {
        td, .row-item {
            height: 38px;
        }
    }

    .asset-reporting-form {
        display: inline-block;
    }

    .asset-info-table, .reporting-asset-info-table {
        input.ng-dirty.ng-invalid {
            border: 1px solid red;
        }

        .alt-cost-selector, .alt-acq-date-selector {
            position: relative;
            float: left;
            margin-right: 10px;
        }

        .row-item.cost-row-item {
            min-height: 40px;
            height: auto !important;
        }

        .row-item:nth-child(even) {
            background: var(--ace-neutral-100);
        }

        .row-item:nth-child(odd) {
            background: var(--ace-neutral-white);
        }

        .row-item:first-child {
            background: var(--ace-neutral-100);
            font-weight: bold;
            overflow: hidden !important;
            min-height: 40px;
        }

        .row-item {
            border-bottom: 1px solid var(--ace-neutral-300);
            min-height: 30px;
            padding-top: 4px;
            padding-left: 6px;
            padding-right: 6px;
            overflow-y: auto;
        }

        .row-item > div:first-child {
            display: inline-block;
            font-weight: bold;
            width: 200px;
        }

        .row-item > div:nth-child(3) {
            display: inline-block;
            width: 225px;
        }

        .row-item > div:nth-child(4) {
            display: inline-block;
            width: 50px;
        }

        .row-item > div {
            vertical-align: top;
        }

        .row-item > div:nth-child(2) {
            display: inline-block;
        }

        .row-item.description-row {
            min-height: 80px;
            height: auto;
        }
    }

    .percentageWarning {
        float: left;
        color: var(--ace-danger);
        font-size: 14px;
        margin-right: 6px;
    }

    .percentageEdit {
        float: right;

        button {
            background-color: green;
        }
    }

    .percentageEdit.costMismatch button {
        background-color: var(--ace-danger);
    }

    .asset-info-table {
        .row-item > div:nth-child(2) {
            width: 230px;
        }
    }

    reporting-asset-info-column {
        .row-item {
            width: 340px;
            padding-right: 6px;
        }

        .clear-override-cell {
            vertical-align: middle !important;
            float: right;
        }

        .split-controls {
            float: right;
            margin-right: -4px;
        }
    }

    reporting-asset-info-column:nth-child(odd) {
        .row-item:nth-child(even) {
            background: var(--ace-neutral-100);
        }

        .row-item:nth-child(odd) {
            background: var(--ace-neutral-200);
        }
    }

    .reporting-asset-info-table {
        .row-item > div:nth-child(2) {
            width: 30px;
        }
    }

    .cost-adjustment-display {
        display: block !important;
    }

    .cost-adjustment-description {
        display: block !important;
        max-width: 400px;
        padding-left: 20px;
        color: var(--ace-danger);
    }

    .cost-adjustment-table {
        display: block;
        border: none;
        white-space: nowrap;

        tr {
            border-bottom: none;
            background: transparent;

            td:first-child {
                min-width: 110px;
            }
        }
    }

    .adjustment-input-holder {
        display: inline-block;
        width: 130px;
        margin-right: 6px;
    }

    .adjustment-input-holder:nth-child(2) {
        display: inline-block;
        width: 75px;
    }

    .calculated-cost {
        text-align: right;
        font-weight: bold;
    }

    .calculated-cost-total {
        @extend .calculated-cost;
        border-top: 1px solid black !important;
    }

    .cost-field-edit {
        display: inline-block;
    }

    .top-align-override-clear {
        vertical-align: top !important;
    }

    .acq-perpetual {
        label {
            margin-right: 4px;
        }
    }

    input.alternative-date-value:disabled {
        width: 96px;
    }

    td.cost-value-holder {
        min-width: 600px;
    }
}

asset-activity-log {
    display: flex;
    flex-flow: column;
    position: relative;
    min-height: 350px;

    .asset-activity-table-odd {
        background-color: var(--ace-neutral-100) !important;
        border-bottom: none !important;
    }

    .asset-activity-table-even {
        background-color: var(--ace-neutral-white) !important;
        border-bottom: none !important;
    }

    .asset-activity-table-last {
        border-bottom: 1px solid var(--ace-neutral-200) !important;
    }

    .asset-activity-table-odd-last {
        @extend .asset-activity-table-odd;
        @extend .asset-activity-table-last;
    }

    .asset-activity-table-even-last {
        @extend .asset-activity-table-even;
        @extend .asset-activity-table-last;
    }
}

asset-bulk-update-modal {

    .bulk-update-tab-hidden {
        display: none;
    }

    .bulk-update-info {
        color: var(ace-neutral-400);
    }

    .parcel-name-shown-disabled {
        background: var(--ace-neutral-100);
        color: var(--ace-neutral-500);
    }

    .adjustment-input-holder {
        display: inline-block;
        width: 140px;
    }

    .text-danger {
        color: var(--ace-danger);
    }

    .asset-verification-reason {
        margin-left: 8px;
    }
}

asset-return-filings {
    display: flex;
    flex-flow: column;
    position: relative;

    .asset-return-filings-status {
        white-space: nowrap;
    }
}

asset-descriptor-edit, asset-descriptor-view {
    input[type="checkbox"].overridden {
        border-color: var(--ace-danger);
        box-shadow: 0px 0px 0px 1px var(--ace-danger);
    }
}

asset-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .modal-dialog {
        overflow-y: initial !important
    }

    .modal-body {
        overflow-y: auto;
    }
}

asset-lien-date {
    display: flex;
    flex-direction: column;

    weissman-datetime {
        margin-left: 8px;
        margin-right: 8px;
    }

    .asset-lien-date-custom {
        color: var(--ace-danger);
    }

    .asset-lien-date-dropdown {
        option {
            text-align: right;
        }
    }
}

.asset-lien-date-current {
    font-weight: bold ;
}

asset-lien-date-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .modal-dialog {
        overflow-y: initial !important
    }

    .modal-body {
        overflow-y: auto;
    }

    weissman-datetime, .asset-lien-date-dropdown {
        margin-left: 8px;
    }

    weissman-datetime {
        margin-right: 8px;
    }

    .asset-lien-date-current {
        font-weight: bold;
    }

    .asset-lien-date-custom {
        color: var(--ace-danger);
    }

    .asset-lien-date-dropdown {
        width: 125px !important;

        option {
            direction: rtl;
        }
    }
}

asset-limit {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .asset-limit-dropdown {
        margin-left: 8px;
    }
}

asset-limit-details {

    .modal-body {

        .filter-label {
            color: var(--ace-neutral-800);
            font-size: 14px;
            padding: 0 5px;
            margin: 3px 0;
        }
    }

    .asset-limit-details-group {
        padding-left: 16px;
    }

    .override-property-column {
        flex: 1 0;
    }
}

.advanced-filter-dropdown {
        margin-left: 8px;
    }

asset-advanced-filter, asset-limit-details {

    .modal-body {

        .filter-label {
            color: var(--ace-neutral-800);
            font-size: 14px;
            padding-left: 5px;
            margin: 3px 0;
        }

        .advanced-filter-quick-list {
            padding-bottom: 10px;

            span {
                font-weight: bold;
                margin-right: 10px;
            }
        }
    }

    .advanced-filter-sub-group {
        padding-left: 20px;
    }
}

.asset-import-in-progress {
    background-color: darkorange;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .message {
        padding-left: 10px;
    }

    .timeRemaining {
        padding-right: 10px;
    }

    .loadingMetadata {
        flex-grow: 1;
    }
}
