@import 'utilities/fonts.scss';

$year-width: 107px;
.equals-bar {
	border-top: 1px solid black;
	// width: $year-width * .75;
}

.annual-header {
	display: inline-block;
	vertical-align: top;
	strong {
		// font-size: $table-header-font-size;
	}
	td {
		border-top: 1px solid lightgrey;
		padding: 5px;
		// &:nth-child(2) {
		// width: $year-width;
		// }
		&:first-child {
			// padding-right: 5px;
		}
	}
	.add-year {
		td {
			border: 0;
			text-align: center;
		}
	}
	.assessments-header {
		.no-padding {
			padding-top: 0;
		}
	}
}

$title-color: white;
.tax-year {
	display: inline-block;
	vertical-align: top;
	.column {
		display: inline-block;
		vertical-align: top;
		max-width: $year-width;
		transition: max-width 0.5s;
		-webkit-transition: max-width 0.5s;
		text-align: right;
		.year-title {
			background-color: var(--ace-info);
			color: $title-color;
			font-size: 120%;
			vertical-align: middle;
			font-weight: bold;
			padding: 2px 0;
			border-left: 1px solid $title-color;
			span {
				margin-right: 5px;
				cursor: pointer;
			}
		}
		.year-data {
			> div {
				border-top: 1px solid lightgrey;
			}
			padding-top: 2px;
			.assessments-appeals-square {
				white-space: nowrap;
				overflow-x: hidden;
				.revision-appeal {
					width: $year-width;
					padding: 5px;
					cursor: pointer;
					display: inline-block;
					&:hover {
						background-color: var(--ace-neutral-100);
					}
					.description {
						font-weight: bold;
					}
				}
				.collapsed-appeal-info.ng-hide-remove {
					-webkit-transition: 0.5s linear all;
					transition: 0.5s linear all;
					-webkit-transition-delay: 0.5s;
					transition-delay: 0.5s;
					/* remember to add this */
					display: block!important;
					opacity: 1;
				}
				.collapsed-appeal-info.ng-hide {
					opacity: 0;
				}
			}
			.not-actual {
				background-color: var(--not-actual-emphasis);
				.revision {
					&:hover {
						background-color: var(--not-actual-emphasis);
					}
				}
			}
			.appeals-square {
				padding: 5px;
			}
			.taxes-square {
				padding: 5px;
			}
		}
	}
	.expanded-tax-year {
		max-width: 500px;
	}
	.year-details {
		display: inline-block;
		border: none;
	}
}

.tax-year.ng-hide-add,
.tax-year.ng-hide-remove {
	-webkit-transition: 0.5s linear opacity;
	transition: 0.5s linear opacity;
	/* remember to add this */
	// display: block!important;
	opacity: 1;
}

.tax-year.ng-hide-remove {
	transition-delay: 0.5s;
}

.tax-year.ng-hide {
	opacity: 0;
}

.annual-details-side-panel {
	width: 100%;
	background-color: var(--ace-neutral-050);
	border-collapse: separate;
	box-shadow: var(--ace-shadow);
	padding: 16px;
	border: solid 1px var(--ace-neutral-300);
}

.annual-details-buttons {
	float: right;
	padding-right: 6px;
}

// Maybe existing code here?
.strike-thru-status {
	.appeals-status {
		text-decoration: line-through;
	}
}

.strike-thru-savings {
	.appeals-savings {
		text-decoration: line-through;
	}
}

.date-time-override {
	.weiss-datepicker-input {
		color: var(--ace-danger);
	}
}

@media screen and (max-width: 1350px) and (min-width: 1200px) {
	.assessment-target-value {
		margin-right: -56px;
	}
}

.intake-indicator {
	margin-right: 5px;
	margin-top: 8px;
	color: var(--ace-tertiary-color);
	vertical-align: top !important;
}
