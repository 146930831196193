/*
The "images" folder within "styles" supports copy/pasted styles from the pdf.js viewer. It so
happens that we're not using styles that reference those images, but having them helps the build
succeed.

I'd love to use angular.json to specify external dependencies, but the order gets messed up
(I seem to be having the same trouble from here: https://github.com/angular/angular-cli/issues/9475).
*/

/* External dependencies */

/* ACE */
@import '../../node_modules/@ace/components/dist/collection/components/ace-styles/ace-styles.css';

/* Bootstrap variable overrides */
$breadcrumb-divider: quote(">");
$table-bg: transparent;
$table-striped-bg: var(--ace-neutral-050);
$body-color: #2b2c36; /* --ace-neutral-800 - doesn't compile if using var() for some reason*/
$input-placeholder-color: var(--ace-neutral-500);
//$link-color: #0a8287;
//$btn-bg: #0a8287;
//$btn-bg-hover: #065458;
$link-color: #5373b4;
$btn-bg: #5373b4;
$btn-bg-hover: #3a5a9b;

/* Import bootstrap */
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/angular-xeditable/dist/css/xeditable.css';
@import '../third-party/angular-ui-select/dist/select.css';
@import '../../node_modules/jquery-ui/themes/base/all.css';
@import '../../node_modules/ngx-toastr/toastr.css';
@import '../third-party/quill-1.3.6/quill.core.css';
@import '../third-party/quill-1.3.6/quill.bubble.css';
@import '../third-party/quill-1.3.6/quill.snow.css';
@import '../third-party/bootstrap-switch-3.3.2/bootstrap-switch.css';
@import '../third-party/ag-grid-community/ag-theme-balham.css';
@import '../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';
@import '../../node_modules/@fortawesome/fontawesome-free/css/solid.css';
@import '../../node_modules/@fortawesome/fontawesome-free/css/brands.css';
@import '../../node_modules/@fortawesome/fontawesome-free/css/v4-shims.css';
@import '../../node_modules/@fortawesome/fontawesome-free/css/regular.css';

/* Bootstrap overrides */
@import 'bootstrap-upgrade.scss';

 /* Internal scss */
@import 'ace.scss';
@import 'action-view.scss';
@import 'activity.scss';
@import 'admin.scss';
@import 'advanced-search.scss';
@import 'ag-grid-expand-cell';
@import 'annual-details.scss';
@import 'appeals.scss';
@import 'attachments.scss';
@import 'bulk-import.scss';
@import 'client-services.scss';
@import 'comment.scss';
@import 'company.scss';
@import 'compliance/asset.scss';
@import 'compliance/compliance-parcel.scss';
@import 'compliance/compliance-variables.scss';
@import 'compliance/compliance.scss';
@import 'compliance/entity-import.scss';
@import 'compliance/return-pdf.scss';
@import 'compliance/return.scss';
@import 'compliance/supplemental-information.scss';
@import 'compliance/allocation.scss';
@import 'components.scss';
@import 'contact.scss';
@import 'contracts-invoices.scss';
@import 'delivery-details.scss';
@import 'documents.scss';
@import 'dropdown-submenu.scss';
@import 'file-picker.scss';
@import 'form-viewer.scss';
@import 'tax-rate.scss';
@import 'user-group.scss';
@import 'income-statement.scss';
@import 'main.scss';
@import 'move-copy.scss';
@import 'navbar-top.scss';
@import 'parcel.scss';
@import 'payment-batch.scss';
@import 'pdf-form-viewer.scss';
@import 'processing.scss';
@import 'property-characteristics.scss';
@import 'reporting.scss';
@import 'roles.scss';
@import 'search.scss';
@import 'site.scss';
@import 'tables.scss';
@import 'tasks.scss';
@import 'teams.scss';
@import 'total-fmv.scss';
@import 'user-setup.scss';
@import 'utilities/fonts.scss';
@import 'utilities/helpers.scss';
@import 'utilities/layouts.scss';
@import 'utilities/mixins.scss';
@import 'vendor/angular-resizable.scss';
@import 'vendor/ng2-dragula.scss';

/* New styles */
@import 'Global/fonts';
@import 'Global/buttons.scss';
@import 'Global/buttons-override.scss';
@import 'Global/agGrid.scss';
@import 'Global/form-controls.scss';
