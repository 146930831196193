@import 'utilities/fonts.scss';

.document-intake {
	//Document intake code goes here
	.page-header {
		margin:0px;
		h1 {
			font-family: $header-font;
			font-size: $page-header-font-size;
		}
	}

	.document-intake-row {
		padding-bottom: 60px;

		.document-intake-filter {
			label {
				margin-top: 5px;
				font-weight: normal;
			}

			input {

			}

			sd-user-team-picker {
				width: 65%;
			}

			sd-company-picker {
				.form-group {
					width: 60%;

					.form-control {
						width: 100%;
					}
				}
			}

			.required-field {
				display: inline-block;
				margin-bottom: 12px;
			}

			.row-label {
				margin-bottom: 14px;
				width: 30%;
				margin-left: 10px;

				&.required {
					margin-bottom: 9px;
					margin-top: -5px;
					vertical-align: bottom;
				}
			}

			.picker-row {
				height: 40px;
			}

			.radio-selectors {
				margin-bottom: 20px;
			}
		}

		.document-intake-file-upload {
			.document-drop {
				p.lead {
					margin-top: 15vh;
					color: gray;
				}
				.drop-box {
					background: var(--ace-neutral-100);
					border: 5px dashed var(--ace-neutral-200);
					width: 50%;
					height: 37vh;
					text-align: center;
					margin: auto;
				}
				.dragover {
					border: 5px dashed blue;
				}
			}
			table.file-list {
				margin: auto;
				margin-top: 30px;
				width: 60%;
			}
			.file-upload-single {
				.preview-window {
					width: 96.5%;
					margin-left: 25px;
					padding: 0;

					//removing this for now
					//height: 79vh;
					height: 772px;
					.preview-window-image {
						max-height: 79vh;
						margin: auto;
					}
				}
			}
		}
	}
}
.preview-modal {
	.modal-lg {
		width: 1200px;
	}
	.modal-content {
		text-align: center;
	}
	.preview-window {
		width: 100%;
		padding: 15px;
		height: 79vh;
		.preview-window-image {
			max-height: 79vh;
			margin: auto;
		}
	}
}

.document-action-view-preview-modal {
	//Action view popup preview modal code goes here
}

.file-preview-page {
    height:100%;
    width:100%;
    overflow-y:hidden;
    .preview-window {
        width: 100%;
        height: 100%;
    }
}

/* Absolute Center Spinner */
.intake-loading {
  div {
  	position: absolute;
  	top: 44%;
  	left: 44%;
  	z-index: 999;
  }
}

/* Transparent Overlay */
.intake-loading:before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 999;
}

.popover-pre-wrap {
	white-space: pre-wrap;
}


