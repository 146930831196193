@import 'utilities/fonts.scss';
@import 'utilities/mixins.scss';

.config-delivery-details{
    .client-abbreviation{
        padding-left: 8px;
        padding-bottom: 16px;

        label{
            width: 100%;
            font-weight: normal;
        }

        input[type="text"]{
            margin-left: 8px;
            display: inline-block;
            width:72%;
            font-weight: normal;
        }
    }
}
