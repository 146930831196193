svg {
	.chevron-text {
		pointer-events: none;
	}
}

.task-checked, .task-skipped {
	fill: var(--ace-success);
}

.task-checkbox {
	fill: white;
	cursor: pointer;
	text-shadow: 1px 1px 3px black;
}

.task-modal-2 {
	.modal-lg {
		--bs-modal-width: 585px;
	}
}

.task-modal-3 {
	.modal-lg {
		--bs-modal-width: 870px;
	}
}

.task-modal-xl {
	.modal-lg {
		--bs-modal-width: 1155px;
		.modal-content {
			overflow-x: auto;
			white-space: nowrap;
		}
	}
}

.editable-node {
	stroke-width: 1;
	fill: var(--ace-warning-hover);
}

