.service-responsibility {
	min-height: 30px;
	&:last-child {
		margin-bottom: 10px;
	}
	div {
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		margin-top: 2px;
	}

	.exception-link {
		float:right;
		margin-right: 14px;
	}

	.from-date-picker {
		display:block;
		margin-bottom: 6px;
	}

	.property-type {
		width: 30px;
	}
	.overridden {
		color: var(--ace-danger);
	}
}

.delivery-details {
	width: 100%;
	margin-bottom: 20px;

	td {
		&:first-child {
			font-weight: bold;
		}

		&:nth-child(4) {
			input, select {
				display: inline-block;
			}

			select.form-select-sm {
				width: auto;
			}

			white-space: nowrap;
		}

		padding-right: 10px;

		&:nth-child(3) {
			padding-right: 0;
		}

		input[type=text] {
			width: 62px;
		}

		select {
			display: inline-block;
			width: auto;
		}
	}
}

.responsibility-exceptions {
	width: 1143px;
}

.consultant-selector {
	padding-left: 21px;
}
