@import 'utilities/fonts.scss';
@import 'utilities/mixins.scss';


/***********************************************
* LABEL
***********************************************/
.label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: var(--ace-neutral-white);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;

    &.label-default {
        background-color: var(--ace-neutral-600);
    }

    &.label-primary {
        --ace-action: #5373b4;
        background-color: var(--ace-action);
    }
}

/***********************************************
* PANELS
***********************************************/

@media (min-width: 750px) {
	.equal-panel {
		.panel-flat-body {
			height: 350px;
		}
	}
}

.full-panel {
	width: 100%;
}

.panel-flat-color {
	background-color: var(--ace-neutral-white);
	box-shadow: var(--ace-shadow);
	margin-bottom: 20px;
	width:100%;
    border: none;
    border-radius: var(--ace-border-radius);
    overflow: hidden;

	.img-panel-button {
		max-height: 50px;
		max-width: 50px;
		margin:15px;
	}

    .card-body {
        margin-top: 14px;
    }

	.panel-flat-header {
		padding: 5px 20px;
		height: 34px;

		h3 {
			font-size: $panel-header-font-size;
			font-family: $panel-header-font;
			text-transform: uppercase;
			margin: 2px 0 0 0;
			display: inline-block;

			&.short-header {
				max-width: 60%;
				width: 60%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.btn, select {
			font-family: $body-font;
		}

        .ace-btn.btn-link {
            --fg-color: var(--ace-neutral-700);

            &:disabled {
                --fg-color: var(--ace-neutral-200);
            }
        }

		.header-icon {
			width: 37px;
			height: 34px;
			font-size: 17px;
			padding: 6px 8px;
			float: left;
			margin:-6px 12px 0px -20px;
		}

		.header-button-container {
			margin-right: -16px;

			.has-icon {
				@include icon-only-button;
			}
		}

		&.small-header {
			padding: 3px 20px;
			height: 26px;
			margin-bottom: 0;
			border-bottom: 1px solid var(--ace-neutral-300);

			h3 {
				margin-top: 1px;
			}
		}
	}

	&.primary-panel, &.secondary-panel {

		.panel-flat-header {
			background-color: var(--ace-neutral-200);
			color: var(--ace-neutral-700);

			button.btn-text {
                .has-icon, i {
                    //important tag because bootstrap keeps trying to override it
                    color: var(--ace-neutral-700) !important;
                }

                &:disabled .has-icon, &:disabled i {
                    color: var(--ace-neutral-600) !important;
                }
			}
			/*.header-icon {
				background-color: var(--ace-neutral-600);
			}*/
		}
	}

	&.small-panel {
		box-shadow: initial;
		border: solid 1px var(--ace-neutral-200);
	}

	.panel-flat-body {
		padding: 12px 20px;
		overflow: auto;
		position: relative;
		min-height: 200px;

		&.site-parcel-list-panel-height {
			height: 410px;
		}

		.overlay:not(image-cropper div) {
			content: " ";
			z-index: 10;
			display: block;
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			background: rgba(255, 255, 255, 1);
			text-align: center;
			color: var(--ace-neutral-700);

			h2 {
				font-family: $panel-header-font;
				margin: 0 auto;
				text-transform: uppercase;
				font-size: 32px;
				padding-bottom: 30px;
			}

			.overlay-content {
				margin-top: 30px;
				padding: 0px 30px;
				font-size: 16px;
			}
		}

		.tab-buttons {
			margin: -10px 2px 10px 0px;
		}

		.well {
			box-shadow: var(--ace-shadow);
		}
	}

	.panel-flat-body-alt {
		padding: 12px 20px;
		overflow: auto;
		position: relative;
		min-height: 400px;

		&.site-parcel-list-panel-height {
			height: 410px;
		}

		.overlay:not(image-cropper div) {
			content: " ";
			z-index: 10;
			display: block;
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			background: rgba(255, 255, 255, 1);
			text-align: center;
			color: var(--ace-neutral-700);

			h2 {
				font-family: $panel-header-font;
				margin: 0 auto;
				text-transform: uppercase;
				font-size: 32px;
				padding-bottom: 30px;
			}

			.overlay-content {
				margin-top: 30px;
				padding: 0px 30px;
				font-size: 16px;
			}
		}

		.tab-buttons {
			margin: -10px 2px 10px 0px;
		}

		.well {
			box-shadow: var(--ace-shadow);
		}
	}

	.panel-flat-footer {
		padding: 12px 20px;
	}
}

.panel-flat-color.flex-panel {
	margin: 0;
	padding-bottom: 20px;

	flex: 1 1 auto;
	display: flex;
	flex-direction: column;

	.panel-flat-header {
		flex: none;
	}

	.panel-flat-body {
		min-height: auto;
		flex: 1 1 auto;
		display: flex;
		flex-flow: column;
	}

	.panel-flat-footer {
		flex: none;
	}
}


/***********************************************
* TABLES
***********************************************/
.annual-details-table {
	&thead:first-child {
		border-bottom: 0;
	}
}

table {
	td {
		.hidden-col-hover {
			display: none;
		}

		&:hover {
			.hidden-col-hover {
				display: block;
			}
		}
	}

	tr {
		.hidden-row-hover {
			display: none;
		}

		&:hover {
			.hidden-row-hover {
				display: block;
			}
		}
	}

	.delete-hover {
		.hidden-hover {
			display: none;
		}

		.invisible-hover {
			visibility: hidden;
		}


		&:hover {
			.hidden-hover {
				display: block;
			}

			.invisible-hover {
				visibility: visible;
			}
		}
	}
}

.hidden-hover {
	.hidden-item {
		display: none;
	}

	&:hover {
		.hidden-item {
			display: block;
		}
	}
}

/* TODO: Refactor this to something more generic for reuse */
/***********************************************
* PARCEL TYPE ABBR LABELS
***********************************************/
.property-type-label {
	float:left;
	margin-top: -4px;
	margin-left: -4px;
	padding-right: 10px;
}

.property-type-PP {
    //background-color: var(--ace-viz-blue-gray);
    background-color: #BA852E;
    //color: var(--ace-neutral-700);
}

.property-type-RE {
    //background-color: var(--ace-viz-spring-green);
    background-color: #68232D;
    //color: var(--ace-neutral-700);
}

.property-type-MN {
    //background-color: var(--ace-viz-peach);
    background-color: #FFD6E0;
    color: var(--ace-neutral-700);
}

.property-type-CA {
    //background-color: var(--ace-viz-cherry-blossom);
    background-color: #B9D9FF;
    color: var(--ace-neutral-700);
}

/* todo: move tab code here, make generic
/***********************************************
* TABS
***********************************************/
.small-tab-header {
	font-size: 13px;
}

///////////////////////////////////////////////////////////////////////
// I don't know why this was added, but it makes tabs objectively worse
// commenting it out makes them more visible, especially disabled tabs
// Leaving it here in case there was a reason for it
///////////////////////////////////////////////////////////////////////
//angular 2 bootstrap tab styling
// .nav-tabs {
//     li {
//         height: 41px;

//         a {
//             border-top: solid 1px var(--ace-neutral-300);
//             border-right: solid 1px var(--ace-neutral-300);
//             border-bottom: none;
//             border-left: solid 1px var(--ace-neutral-300);
//             color: var(--ace-neutral-500);

//             &:hover {
//                 background-color: var(--ace-neutral-200) !important;
//                 color: var(--ace-neutral-white);
//             }

//             &.active {
//                 //Important tags are because something in bootstrap was causing this to revert back until another action was taken
//                 //since this is a third party tool, I think important is okay. Not ideal but ok.
//                 //Also this is only for angular 2 tabs
//                 color: var(--ace-neutral-700) !important;
//                 font-weight: bold;
//                 background-color: var(--ace-neutral-100);
//                 border-top: solid 1px var(--ace-neutral-300);
//                 border-right: solid 1px var(--ace-neutral-300);
//                 border-bottom: none;
//                 border-left: solid 1px var(--ace-neutral-300);

//                 &:hover {
//                     //I wish this wasn't necessary, but it's to override the bootstrap CSS
//                     color: var(--ace-neutral-700);
//                     background-color: var(--ace-neutral-200);
//                     border-top: solid 1px var(--ace-neutral-300);
//                     border-right: solid 1px var(--ace-neutral-300);
//                     border-bottom: none;
//                     border-left: solid 1px var(--ace-neutral-300);
//                     cursor: pointer;
//                 }
//             }
//         }
//     }
// }

//angular 1 bootstrap tab styling. The difference is where the active flag is set.
.nav-tabs {
    li {
        &.active {
            a {
                color: var(--ace-neutral-700) !important;
                font-weight: bold;
                background-color: var(--ace-neutral-100);
                border-top: solid 1px var(--ace-neutral-300);
                border-right: solid 1px var(--ace-neutral-300);
                border-bottom: none;
                border-left: solid 1px var(--ace-neutral-300);

                &:hover {
                    //I wish this wasn't necessary, but it's to override the angular 1 version of bootstrap CSS
                    color: var(--ace-neutral-700) !important;
                    font-weight: bold;
                    background-color: var(--ace-neutral-200) !important;
                    border-top: solid 1px var(--ace-neutral-300);
                    border-right: solid 1px var(--ace-neutral-300);
                    border-bottom: none;
                    border-left: solid 1px var(--ace-neutral-300);
                    cursor: pointer;
                }
            }
        }

        a {
            &:hover {
                color: var(--ace-neutral-white);
                background-color: var(--ace-neutral-200) !important;
            }
        }
    }
}

//Quick fix to add padding below the border bottom of tabs
.tab-content {
	.tab-pane.active {
		margin-top:8px;
	}
}



.column-filters {
	width: 100%;
	margin-bottom: 20px;
	> tbody {
		> tr {
			> td {
				padding: 2px 0;
			}
		}
	}
	.filter-name {
		font-weight: bold;
		text-align: left;
		width: 1px;
		white-space: nowrap;
		vertical-align: top;
		padding-top: 8px;
	}
	.filter-ors {
		table {
			width: 100%;
			td {
				padding: 0 5px;
			}
			.or-or,
			.or-delete {
				width: 1px;
			}
			.or-operator {
				width: 33%;
			}
			.or-input {
				width: 66%;
			}
		}
	}
	.filter-or-button {
		width: 1px;
		vertical-align: bottom;
		padding-bottom: 7px;
	}
}

/***********************************************
* ACTION BAR
***********************************************/
.action-bar {
	border: solid 1px var(--ace-neutral-300);
	box-shadow: var(--ace-shadow);
	padding: 5px 20px;
	background-color: white;
	border-radius: 8px;
	label {
		margin-right: 20px;
	}
	> div {
		display: inline-block;
		vertical-align: middle;
	}
}

/***********************************************
* ICON BADGE
***********************************************/

.icon-badge, .small-icon-badge {
    &.blue-badge {
        background-color: var(--ace-info);
        border: 2px solid var(--ace-info);
    }
    &.gold-badge {
        background-color: var(--ace-tertiary-color);
        border: 2px solid var(--ace-tertiary-color);
    }
    &.green-badge {
        background-color: var(--ace-success);
        border: 2px solid var(--ace-success);
    }
    &.red-badge {
        background-color: var(--ace-viz-pansy);
        border: 2px solid var(--ace-viz-pansy);
    }
}

.icon-badge {
	margin-left: 4px;
	width: 30px;
	height: 30px;
	display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 20px;
	color: var(--ace-neutral-white);
	box-shadow: var(--ace-neutral-500) 1px 1px 3px;
}

/***********************************************
* MODAL OVERRIDES
***********************************************/
.fixed-height-modal {
	.modal-dialog {
		height: 82vh;
		.modal-content {
			overflow-y: auto;
			overflow-x: hidden;
			height: 80vh;
		}
	}
}

.modal-content {
	border-radius: 0px;

	.modal-header {
		border: none;

        h4 {
            display: inline;
        }
	}

	.modal-body:not(.scenario):not(.contact-modal):not(.exclude-modal-styling):not(.custom-output):not(.retrieval-status){
		padding: 15px 60px 40px 60px;

        &.small-margin-modal {
            padding: 15px 30px 40px 30px;
        }

		&.task-modal, &.attachment-modal {
			padding: 15px;
		}

		label:not(.exclude-modal-styling) {
			color: var(--ace-neutral-500);
			font-size: 13px;
		}

		input[type=text]:not(.exclude-modal-styling), input[type=number], input[type=tel] {
			border-left: none;
			border-right: none;
			border-top: none;
			box-shadow: none;

			margin-top: -2px;
			margin-bottom: 35px;

			padding: 10px 0 10px 0;

			border-radius: 0px;
		}

	}

	.modal-footer {
		border: none;

        button {
            margin-left: 5px;
        }
	}
}

/***********************************************
* ALPHABET PAGER
***********************************************/
.alphabet-pager {
	font-weight: bold;

	li {
		&.disabled a {
			background-color: var(--ace-neutral-100);
			font-weight: normal;

			&:hover {
				background-color: var(--ace-neutral-100);
			}
		}

		a {
			&:hover {
				background-color: var(--ace-info-secondary-hover);
			}
		}
	}
}

/***************************************************
* DATA TEXT FIELD
***************************************************/
.data-text-field {
	border: 1px solid lightgray;
	padding: 8px;
	box-shadow: 1px 1px 6px var(--ace-neutral-600);
}

/***************************************************
* BREADCRUMB
***************************************************/
.breadcrumb {
	// Bootstrap overrides
	padding: 8px 15px 0px 2px;
	margin-bottom: 0px;

	li {
		a {
			font-weight: normal;
		}
	}

	.active {
		font-style: italic;

		&:before {
			content: '\003E';
			color: var(--ace-neutral-800);
		}
	}
}

.breadcrumb > li + li:before {
	content: '\003E';
	color: var(--ace-neutral-800);
}

/***************************************************
* SORTABLE (DRAG-AND-DROP)
***************************************************/
.wsSortable {
	padding: 4px;
	border: 1px dashed var(--ace-neutral-300);

	&__item {
		margin: 4px 0;
		padding: 4px;
		background-color: var(--ace-neutral-050);
		vertical-align: middle;

		&--active {
			background-color: var(--ace-neutral-500);
			color: var(--ace-neutral-white);
		}
	}
}

.multi-select {
	@include multi-select;
}

.email-template-z-index {
	z-index: 1400;
}

/***************************************************
* LOADING INDICATOR
***************************************************/
span.spinner-label-text {
    font-size: 2em;
    vertical-align: middle;
    display: inline-block;
    height: 3em;
    margin-left: 0.5em;
}


/***************************************************
* ANCHOR TAGS (INTERNAL LINKS IN ANGULAR 2+)
***************************************************/
span.anchor-style {
    --ace-action: #5373b4;
    color: var(--ace-action);
}

span.overridden-anchor-style {
    color: var(--ace-danger);
}

span.anchor-style,span.overridden-anchor-style {
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: var(--ace-action-hover);
		text-decoration: underline;
	}
}

/***************************************************
* CHECKBOX
***************************************************/
.checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;

    input[type=checkbox] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        cursor: pointer;

        &:checked {
            & ~ .checkbox-checkmark {
                background-color: #5373B4;
                border: none;
            }

            & ~ .checkbox-checkmark:after {
                display: block;
            }
        }
    }

    .checkbox-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: var(--ace-neutral-white);
        border: 1px solid var(--ace-neutral-200);

        &:after {
            left: 8px;
            top: 4px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &:hover input ~ .checkmark {
        background-color: var(--ace-neutral-300);
    }

    &.disabled {
        cursor: default;
    }
}

/***************************************************
* SELECT
***************************************************/
.input-xs {
    height: 22px;
    line-height: 22px;
    padding: 2px 10px 2px 0;
}

/***************************************************
* Override font-awesome v6 prefers-reduced-motion setting
***************************************************/
@media (prefers-reduced-motion) {
	.fa-spin-pulse, .fa-pulse {
		-webkit-animation-name: fa-spin !important;
		animation-name: fa-spin !important;
		-webkit-animation-direction: var(--fa-animation-direction, normal) !important;
		animation-direction: var(--fa-animation-direction, normal) !important;
		-webkit-animation-duration: var(--fa-animation-duration, 1s) !important;
		animation-duration: var(--fa-animation-duration, 1s) !important;
		-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite) !important;
		animation-iteration-count: var(--fa-animation-iteration-count, infinite) !important;
		-webkit-animation-timing-function: var(--fa-animation-timing, steps(8)) !important;
		animation-timing-function: var(--fa-animation-timing, steps(8)) !important;
	}

	.fa-spin {
		-webkit-animation-name: fa-spin !important;
		animation-name: fa-spin !important;
		-webkit-animation-duration: var(--fa-animation-duration, 2s) !important;
		animation-duration: var(--fa-animation-duration, 2s) !important;
		-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite) !important;
		animation-iteration-count: var(--fa-animation-iteration-count, infinite) !important;
		-webkit-animation-timing-function: var(--fa-animation-timing, linear) !important;
		animation-timing-function: var(--fa-animation-timing, linear) !important;
	}

}


.help-tooltip {
	.tooltip-inner {
		color: #444444;
		background-color: var(--ace-neutral-white);
		box-sizing: border-box;
		box-shadow: 0 1px 3px rgba(0,0,0,0.5);
	}

	.tooltip-arrow {
		display: none;
	}
}
