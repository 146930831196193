@import './compliance-variables.scss';

/** Overview **/
return-summary-reconciliation-report,
schedule-additions-disposals-reconciliation-report,
filing-control-reconciliation-report {
    position: relative;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    overflow: auto;
}

/** Assets **/
return-asset-list, return-asset-disposals-list {
    .state-icon {
        width: 20px;
        height: 20px;
        line-height: 17px;
        border: 2px solid var(--ace-neutral-700);
        display: block;
        text-align: center;
        margin: 3px auto 0 auto;
        border-radius: 50%;
        background-color: white;
        content: '';
        font: var(--fa-font-regular);
        font-style: normal;
        font-weight: 900;
        text-decoration: inherit;
        font-size: 12px;

        i {
            line-height: 16px;
        }

        &.status-changed {
            color: var(--ace-danger);
        }

    }
}

asset-class-picker-modal {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    label {
        font-weight: normal;
        margin-right: 15px;
        margin-bottom: 8px;
    }

    .modal-body {
        max-height: $modal-max-height;
        overflow-y: auto;
    }
}

/** Return Settings **/
return-settings-report-add {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    .modal-body {
        height: 500px;
        overflow-y: auto;
    }
}

filing-control-reconciliation-report {
    .ag-layout-normal .ag-floating-bottom {
        .ag-row {
            background: var(--ace-neutral-100);
        }

        .totals-row, .selected-totals-row {
            .ag-cell.ag-cell-with-height.ag-cell-value:empty {
                background: var(--ace-neutral-100);

                &::after {
                    content: '-';
                }
            }
        }

        .totals-row {
            .ag-cell.ag-cell-with-height.ag-cell-value:not(:empty) {
                background: var(--ace-neutral-100);
            }
        }

        .ag-floating-bottom-viewport {
            .selected-totals-row {
                .ag-cell.ag-cell-with-height.ag-cell-value:not(:empty) {
                    background: var(--ace-neutral-200);
                }
            }
        }
    }
}

filing-control-list-reconciliation-report {
    flex: 1 1 auto;

    .ag-theme-balham .cell-validation-error {
        border-left-style: solid;
        border-left-color: var(--ace-danger);
        border-left-width: 6px;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
        background: var(--ace-danger-ghosted);
    }
}

filing-control-grouped-reconciliation-report {
    flex: 1 1 auto;

    .ag-theme-balham .cell-validation-error {
        border-left-style: solid;
        border-left-color: var(--ace-danger);
        border-left-width: 6px;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
        background: var(--ace-danger-ghosted);
    }
}

filing-control-reconciliation-report-assessor {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;

    .assessor-summary {
        --ace-action: #5373b4;
        background-color: var(--ace-action);
        color: var(--ace-neutral-white);
        padding: 8px 8px 8px 4px;
        height: 26px;
    }

    .assessor-details {
        background-color: var(--ace-neutral-100);
        color: var(--ace-neutral-700);
        height: 86px;
        padding: 4px 12px 4px 12px;

        .no-protocols {
            font-style: italic;
            font-weight: 400;
        }

        .address-line-item:not(:first-child) {
            margin-left: 4px;
        }
    }

    .assessor-protocols {
        text-align: right;

        .assessor-protocol {
            text-align: right;
        }
    }
}

return-task {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

return-task-exception {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

return-task-exception-modal {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .exception-option {
        padding-left: 8px;
    }

    .exception-user-team {
        padding-left: 24px;
        margin-bottom: 8px;
    }
}

return-sign-modal {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    label {
        font-weight: normal;
        margin-right: 15px;
    }

    .grid-wrapper {
        min-height: 250px;
    }
}

.scheduleSelectorPath {
    color: var(ace-neutral-400);
    font-style: italic;
}

schedule-selector {
    .input-group {
        width: 100%;
    }
}

.ag-theme-balham .ag-ltr .cell-validation-warning {
    border-left-style: solid !important;
    border-left-color: var(--ace-warning);
    border-left-width: 6px;
    border-bottom-style: none;
    border-top-style: none;
    border-right-style: none;
    background: var(--ace-warning-ghosted);
}
