/***********************************************
* BUTTONS
***********************************************/
button.round {
    border-radius: 50rem;
}

.ace-btn {
    --rad-left: 1;
    --button-border-width: 1px;
    --button-border-radius: var(--ace-border-radius);

    &.btn-primary {
        --fg-color: var(--ace-neutral-white);
        --hover-color: var(--ace-neutral-white);
        --pressed-color: var(--ace-neutral-white);
        --disabled-color: var(--ace-neutral-white);
        --disabled-bg-color: var(--ace-disabled-color);
        --disabled-border-color: var(--ace-disabled-color);
        --bg-color: var(--ace-action);
        --border-color: var(--ace-action);
        --hover-bg-color: var(--ace-action-hover);
        --hover-border-color: var(--ace-action-hover);
        --pressed-bg-color: var(--ace-action-pressed);
        --pressed-border-color: var(--ace-action-pressed);
    }

    &.btn-secondary {
        --bg-color: var(--ace-neutral-white);
        --pressed-border-color: var(--pressed-color);
        --disabled-color: var(--ace-disabled-color);
        --disabled-bg-color: var(--ace-neutral-white);
        --disabled-border-color: var(--ace-disabled-color);
        --fg-color: var(--ace-action);
        --border-color: var(--ace-action);
        --hover-color: var(--ace-action-hover);
        --hover-bg-color: var(--ace-action-secondary-hover);
        --hover-border-color: var(--ace-action-hover);
        --pressed-color: var(--ace-action-pressed);
        --pressed-bg-color: var(--ace-action-secondary-pressed);
    }

    &.btn-text {
        --button-border-width: 0;
        --bg-color: transparent;
        --hover-bg-color: transparent;
        --pressed-bg-color: transparent;
        --disabled-bg-color: transparent;
        --disabled-color: var(--ace-disabled-color);
    }

    &.btn-link {
        --button-border-radius: 1px;
        --button-border-width: 0;
        --hover-decoration: underline;
        --bg-color: transparent;
        --hover-bg-color: transparent;
        --pressed-bg-color: transparent;
        --disabled-bg-color: transparent;
        --disabled-color: var(--ace-disabled-color);
        --fg-color: var(--ace-action);
        --hover-color: var(--ace-action-hover);
        --pressed-color: var(--ace-action-pressed);
        display: inline;
        padding: 0 !important;
        font-weight: 400 !important;
        font-size: inherit;
        text-transform: none;
        letter-spacing: normal;
        line-height: var(--ace-body-line-height);
        height: auto !important;
        width: auto !important;
        min-height: unset !important;
        min-width: unset !important;
        border: none;
        appearance: none;
        text-decoration: none;

        &:not(.disabled):hover {
            text-decoration: var(--hover-decoration);
        }

        &:not(.disabled):active, :host(.btn-link) button:not(.disabled).active, :host(.btn-link) button:not(.disabled):focus {
            text-decoration: var(--hover-decoration);
        }
    }

    &.btn-primary.btn-action {
        --bg-color: var(--ace-action);
        --border-color: var(--ace-action);
        --hover-bg-color: var(--ace-action-hover);
        --hover-border-color: var(--ace-action-hover);
        --pressed-bg-color: var(--ace-action-pressed);
        --pressed-border-color: var(--ace-action-pressed);
    }

    &.btn-secondary.btn-action {
        --fg-color: var(--ace-action);
        --border-color: var(--ace-action);
        --hover-color: var(--ace-action-hover);
        --hover-bg-color: var(--ace-action-secondary-hover);
        --hover-border-color: var(--ace-action-hover);
        --pressed-color: var(--ace-action-pressed);
        --pressed-bg-color: var(--ace-action-secondary-pressed);
    }

    &.btn-text.btn-action,
    &.btn-link.btn-action {
        --hover-color: var(--ace-action-hover);
        --pressed-color: var(--ace-action-pressed);
    }

    &.btn-text.btn-action:not(.rest-neutral),
    &.btn-link.btn-action:not(.rest-neutral) {
        --fg-color: var(--ace-action);
    }

    &.btn-primary.btn-info {
        --bg-color: var(--ace-info);
        --border-color: var(--ace-info);
        --hover-bg-color: var(--ace-info-hover);
        --hover-border-color: var(--ace-info-hover);
        --pressed-bg-color: var(--ace-info-pressed);
        --pressed-border-color: var(--ace-info-pressed);
    }

    &.btn-secondary.btn-info {
        --fg-color: var(--ace-info);
        --border-color: var(--ace-info);
        --hover-color: var(--ace-info-hover);
        --hover-bg-color: var(--ace-info-secondary-hover);
        --hover-border-color: var(--ace-info-hover);
        --pressed-color: var(--ace-info-pressed);
        --pressed-bg-color: var(--ace-info-secondary-pressed);
    }

    &.btn-text.btn-info,
    &.btn-link.btn-info {
        --hover-color: var(--ace-info-hover);
        --pressed-color: var(--ace-info-pressed);
    }

    &.btn-text.btn-info:not(.rest-neutral),
    &.btn-link.btn-info:not(.rest-neutral) {
        --fg-color: var(--ace-info);
    }

    &.btn-primary.btn-success {
        --bg-color: var(--ace-success);
        --border-color: var(--ace-success);
        --hover-bg-color: var(--ace-success-hover);
        --hover-border-color: var(--ace-success-hover);
        --pressed-bg-color: var(--ace-success-pressed);
        --pressed-border-color: var(--ace-success-pressed);
    }

    &.btn-secondary.btn-success {
        --fg-color: var(--ace-success);
        --border-color: var(--ace-success);
        --hover-color: var(--ace-success-hover);
        --hover-bg-color: var(--ace-success-secondary-hover);
        --hover-border-color: var(--ace-success-hover);
        --pressed-color: var(--ace-success-pressed);
        --pressed-bg-color: var(--ace-success-secondary-pressed);
    }

    &.btn-text.btn-success,
    &.btn-link.btn-success {
        --hover-color: var(--ace-success-hover);
        --pressed-color: var(--ace-success-pressed);
    }

    &.btn-text.btn-success:not(.rest-neutral),
    &.btn-link.btn-success:not(.rest-neutral) {
        --fg-color: var(--ace-success);
    }

    &.btn-primary.btn-danger {
        --bg-color: var(--ace-danger);
        --border-color: var(--ace-danger);
        --hover-bg-color: var(--ace-danger-hover);
        --hover-border-color: var(--ace-danger-hover);
        --pressed-bg-color: var(--ace-danger-pressed);
        --pressed-border-color: var(--ace-danger-pressed);
    }

    &.btn-secondary.btn-danger {
        --fg-color: var(--ace-danger);
        --border-color: var(--ace-danger);
        --hover-color: var(--ace-danger-hover);
        --hover-bg-color: var(--ace-danger-secondary-hover);
        --hover-border-color: var(--ace-danger-hover);
        --pressed-color: var(--ace-danger-pressed);
        --pressed-bg-color: var(--ace-danger-secondary-pressed);
    }

    &.btn-text.btn-danger,
    &.btn-link.btn-danger {
        --hover-color: var(--ace-danger-hover);
        --pressed-color: var(--ace-danger-pressed);
    }

    &.btn-text.btn-danger:not(.rest-neutral),
    &.btn-link.btn-danger:not(.rest-neutral) {
        --fg-color: var(--ace-danger);
    }

    &:not(.no-min).has-text {
        min-width: 6.5rem;
    }

    &:not(.no-min):not(.btn-link).btn-sm .has-text {
        min-width: 6rem;
    }
}

.ace-btn {
    cursor: pointer;
    color: var(--fg-color) !important;
    background: var(--bg-color);
    border-color: var(--border-color);
    border-style: solid;
    border-width: var(--button-border-width);
    //min-height: 3rem;
    margin: 0;
    padding: calc(0.75rem - 1px) calc(1.5rem - 1px);
    border-top-left-radius: calc(var(--button-border-radius) * var(--rad-left));
    border-bottom-left-radius: calc(var(--button-border-radius) * var(--rad-left));
    border-top-right-radius: var(--button-border-radius);
    border-bottom-right-radius: var(--button-border-radius);
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-family: var(--ace-font-family);
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    max-width: 100%;
    //width: 100%;
    --icon-color: var(--fg-color);

    &:not(.has-icon) i {
        margin-right: 0.25rem;
    }

    &.has-icon {
        margin-left: -0.5rem;
        margin-right: 0.5rem;

        &.icon-dark {
            --icon-color: var(--bg-color);
        }

        &.fa {
            --ace-font-family: "Font Awesome 6 Free";
        }
    }

    &.has-icon-right.icon-right {
        margin-right: -0.5rem;
        margin-left: 0.5rem;
    }

    &.has-icon-content {
        margin-right: 0.5rem;
    }

    &.has-icon-right-content {
        margin-left: 0.5rem;
    }

    button:not(:disabled):not(.disabled):not(.no-hover):hover i,
    button:not(:disabled):not(.disabled):not(.no-hover):hover i:not(.no-hover) {
        transform: scale(1.25);
    }

    i.fa-spinner {
        color: var(--fg-color);
    }

    &.has-icon:not(.has-icon-right):not(.has-icon-right-content):not(.has-text),
    &.has-icon-right:not(.has-icon):not(.has-icon-content):not(.has-text) {
        width: 1.5rem;
        //padding: calc(0.5rem - 1px);
        margin: 0 0.25rem 0.625rem 0;
        position: relative;
        font-size: 0.75rem;
        padding: 0;
        height: 1.5rem;

        &.btn-lg {
            font-size: 1.25rem;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            margin: 0.625rem 0 0 0;
        }
    }

    &.btn-text.has-icon:not(.has-icon-right):not(.has-icon-right-content):not(.has-text),
    &.btn-text.has-icon-right:not(.has-icon):not(.has-icon-content):not(.has-text) {
        background-color: transparent;
        margin-right: 6px;
        font-size: 17px;
        transition: all .1s ease-in-out;
        box-shadow: none;

        &:hover {
            transform: scale(1.2);
        }
    }

    &.has-icon:not(.has-icon-right):not(.has-icon-right-content):not(.has-text) slot[name=icon]::slotted(*),
    &.has-icon-content:not(.has-icon-right):not(.has-icon-right-content):not(.has-text) slot[name=icon]::slotted(*),
    &.has-icon-right:not(.has-icon):not(.has-icon-content):not(.has-text) slot[name=icon]::slotted(*),
    &.has-icon-right-content:not(.has-icon):not(.has-icon-content):not(.has-text) slot[name=icon]::slotted(*) {
        margin: unset;
    }

    &.has-icon:not(.has-icon-right):not(.has-icon-right-content):not(.has-text) slot[name=icon-right]::slotted(*),
    &.has-icon-content:not(.has-icon-right):not(.has-icon-right-content):not(.has-text) slot[name=icon-right]::slotted(*),
    &.has-icon-right:not(.has-icon):not(.has-icon-content):not(.has-text) slot[name=icon-right]::slotted(*),
    &.has-icon-right-content:not(.has-icon):not(.has-icon-content):not(.has-text) slot[name=icon-right]::slotted(*) {
        margin: unset;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        --fg-color: var(--hover-color);
        --bg-color: var(--hover-bg-color);
        --border-color: var(--hover-border-color);
    }

    &:disabled {
        --fg-color: var(--disabled-color) !important;
        --bg-color: var(--disabled-bg-color) !important;
        --border-color: var(--disabled-border-color) !important;
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        --fg-color: var(--hover-color);
        --bg-color: var(--hover-bg-color);
        --border-color: var(--hover-border-color);
    }

    &:not(:disabled) :host(active), &:not(:disabled):active {
        --fg-color: var(--pressed-color);
        --bg-color: var(--pressed-bg-color);
        --border-color: var(--pressed-border-color);
    }

    &.btn-sm:not(.btn-text):not(.btn-link) {
        --button-border-width: 1px;
    }

    &.btn-sm:not(.btn-link) {
        //min-height: 2.5rem;
        //font-size: 0.875rem;
        font-size: 0.75rem;
        //padding: calc(0.5rem - 1px) calc(1rem - 1px);
        padding: 0.25rem 0.5rem;
        margin: calc(0.5rem - 3px);
        line-height: 1.125rem;
    }

    &.btn-sm:not(.btn-link).has-icon:not(.has-icon-right):not(.has-icon-right-content):not(.has-text),
    &.btn-sm:not(.btn-link).has-icon-right:not(.has-icon):not(.has-icon-content):not(.has-text) {
        width: 1.75rem;
        height: 1.75rem;
        margin: 5px;
        padding: 0.25rem 0.5rem;
    }

    &.d-block {
        width: 100%;
        max-width: unset;
    }

    @media (max-width: 575px) {
        &.mobile-block {
            width: 100%;
            max-width: unset;
        }
    }

    &:not(:disabled):focus {
        box-shadow: var(--ace-focus-ring);
        z-index: 1;
    }

    &.suppress-wrap {
        white-space: nowrap;
        max-width: var(--ace-button-max-width, 6.5rem);
    }

    &.suppress-wrap span {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.suppress-wrap.btn-sm:not(.btn-link) {
        max-width: var(--ace-button-max-width, 6rem);
    }

    &.btn-primary.btn-danger {
        --ace-focus-ring: 0 0 0 1px var(--ace-neutral-white), 0 0 0 0.25rem var(--ace-danger-ghosted);
        --ace-focus-ring-inset: inset 0px 0px 0px 0.25rem var(--ace-focused-color);
    }
}

.input-group > .ace-btn:not(:first-child):not(.dropdown-menu) {
    &.has-icon {
        margin: 0;
        width: 1.875rem;
        height: 1.875rem;
    }
}

.btn-group {
    .ace-btn {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.dropdown-toggle {
            height: 1.875rem;
            padding: 0.25rem 0.5rem;
            margin-right: 0.25rem;
        };
    }

    &:not(.buttongroup-last) .ace-btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:not(.buttongroup-first) .ace-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }


    &:not(.buttongroup-first) .ace-btn.btn-primary {
        border-left-color: var(--ace-neutral-white);
    }

    &:not(.buttongroup-last) .ace-btn.btn-primary {
        border-right-color: var(--ace-neutral-white);
    }

    &.d-block.buttongroup-first {
        flex-grow: 1;
    }

    &.d-block.buttongroup-first .ace-btn {
        flex-grow: 1;
    }
}

/***************************************************
* ANCHOR TAGS (INTERNAL LINKS IN ANGULAR 2+)
***************************************************/

a {
    color: var(--ace-action);
    text-decoration: none;
    border-radius: 1px;

    &:not(.disabled):active, &:not(.disabled).active, &:not(.disabled):focus {
        color: var(--ace-action);
        text-decoration: underline;
    }

    &:focus:not(:disabled) {
        box-shadow: var(--ace-focus-ring);
    }

    &.btn-primary {
        text-decoration: none;
    }

    &:hover {
        color: var(--ace-action-hover);
        text-decoration: underline;
    }

    &:not([href]):not([class]) {
        color: var(--ace-action);

        &:hover {
            color: var(--ace-action-hover);
            text-decoration: underline;
        }
    }

    &.list-group-item.active {
        color: var(--ace-neutral-white);
        text-decoration: none;
    }
}

span.anchor-style {
    color: var(--ace-action);

    &:hover {
        color: var(--ace-action-hover);
        text-decoration: underline;
    }
}

span.overridden-anchor-style {
    color: var(--ace-danger);

    &:hover {
        color: var(--ace-danger-hover);
        text-decoration: underline;
    }
}

span.anchor-style, span.overridden-anchor-style {
    text-decoration: none;
    cursor: pointer;
    border-radius: 1px;

    &:not(.disabled):active, &:not(.disabled).active, &:not(.disabled):focus {
        text-decoration: underline;
    }

    &:focus:not(:disabled) {
        box-shadow: var(--ace-focus-ring);
    }
}

/***************************************************
* CHECKBOX/RADIO
***************************************************/

input[type=checkbox], input[type=radio] {
    accent-color: var(--ace-info);
    outline-color: var(--ace-neutral-300);

    &:focus {
        box-shadow: var(--ace-focus-ring);
    }

    &:hover {
        accent-color: var(--ace-info-hover);
        outline-color: var(--ace-info);
    }
}

.checkbox, .radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    input[type=checkbox], input[type=radio] {
        position: absolute;
        margin-top: 4px;
        margin-left: -20px;
    }

    label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
    }

    + .radio, + .checkbox {
        margin-top: -5px;
    }
}

.radio-inline, .checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;

    input[type=radio], input[type=checkbox] {
        position: absolute;
        margin-top: 4px;
        margin-left: -20px;
    }

    + .radio-inline, + .checkbox-inline {
        margin-top: 0;
        margin-left: 10px;
    }
}

.checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;

    input[type=checkbox] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        cursor: pointer;
        border-radius: var(--ace-border-radius);

        &:checked {
            & ~ .checkbox-checkmark {
                background-color: var(--ace-info);
                border: none;
            }

            & ~ .checkbox-checkmark:after {
                display: block;
            }
        }
    }

    .checkbox-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: var(--ace-neutral-white);
        border: 1px solid var(--ace-neutral-300);

        &:after {
            left: 8px;
            top: 4px;
            width: 5px;
            height: 10px;
            border: solid var(--ace-neutral-white);
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    &:hover input ~ .checkmark {
        border-color: var(--ace-info);
    }

    &.disabled {
        cursor: default;
    }
}
