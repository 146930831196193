/* Overall notes about styling are in styles.scss */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,700,700italic,900,900italic,300italic,300);
@import 'utilities/fonts.scss';

html {
	height: 100%;
}

body {
	height: inherit;
    color: var(--ace-neutral-700);
	font-family: $body-font;
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	background-color: var(--ace-neutral-100);
	> .dropdown-menu {
		z-index: 1062;
	}
}

//body.show-nav {
//	padding-top: 50px;
//}

body.full-screen div.app-ui-view {
	padding-right: 0px;
	padding-left: 0px;
}

[hidden] {
	display: none !important;
}

.hover-background:hover {
    color: var(--ace-neutral-700);
    background-color: var(--ace-neutral-100);
}


payment-batch-payments-grid {
	.ag-theme-balham {
		.row-error {
			background-color: var(--ace-danger-pressed) !important;
		}
	}
}

.nav,
.pagination,
.panel-title a,
.pointer {
	&:not(.disabled) {
		cursor: pointer;
	}
}

.bill-scenario-modal {
	.modal-lg {
		width: 1143px;
	}
}

.max-modal-size {
	.modal-lg {
		width: 1200px !important;
		height: 900px !important;
		overflow-y: hidden;
		overflow-x: hidden;
	}
	.modal-content {
		width: 1200px !important;
		height: 900px !important;
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

.super-max-modal-size {
	.modal-lg {
		width: 95vw !important;
        max-width: 95vw !important;
	}
	.modal-content {
		width: 95vw !important;
        max-width: 95vw !important;
	}
}

.no-modal-backdrop {
    pointer-events: none;
    .modal-content {
        box-shadow: var(--ace-shadow);
    }
}

.overridden {
	color: var(--ace-danger);
}

.inline-editor-disabled {
    cursor: not-allowed;
}

.label-as-badge {
	border-radius: 1em;
}

.browsehappy {
	margin: 0.2em 0;
	background: var(--ace-neutral-300);
	color: var(--ace-neutral-black);
	padding: 0.2em 0;
}

.ng-aside.vertical.top.search-criteria {
	.modal-sm {
		width: 500px !important;
	}
}

.company {
	display: inline-block;
	vertical-align: top;
	padding: 20px;
	text-align: left;
}

.editable {
	max-width: 250px;
}

.three div {
	padding: 0;
}

.gray {
	background-color: lightgrey;
}

.barely-gray {
	background-color: var(--ace-neutral-100);
}

.entity-header {
	height: 30px;
}

.header-space {
	min-height: 44px;
}

.parcels {
	margin-left: 50px;
	margin-right: 50px;
	margin-top: 42px;
}

.parcel {
	.breadcrumb,
	.activity {
		.panel-title {
			margin-top: 7px;
		}
	}
	.annual-details {
        .appeal-deadline {
            font-size: $table-header-font-size;
            margin-top: 1em;
        }
		.x-scroll {
			overflow-x: auto;
		}
		.card-body,
		.panel-flat-body {
			.grid {
				// text-align: center;
				width: inherit;
				margin-bottom: 0;
				.years {
					th {
						max-width: 108px;
						text-align: center;
						background-color: var(--ace-info);
						color: white;
						font-size: $table-header-font-size;
						vertical-align: middle;
						border-right: 1px solid white;
						-webkit-transition: max-width 0.5s;
						transition: max-width 0.5s;
						padding: 0 8px;
					}
					.blank {
						background-color: white;
						border-bottom: 0;
					}
					.back {
						color: var(--ace-info);
						background-color: white;
					}
				}
				.details {
					$annual-grid-cell-width: 100px;
					> tr {
						> td {
							&:nth-child(1) {
								font-size: $table-header-font-size;
								vertical-align: middle;
							}
						}
					}
					.clickable {
						> div {
							overflow-x: hidden;
							> table {
								margin-left: auto;
							}
						}
						text-align: right;
						max-width: $annual-grid-cell-width;
						vertical-align: middle;
						-webkit-transition: max-width 0.5s;
						transition: max-width 0.5s;
						padding: 0px;
					}
					.not-actual {
						background-color: var(--not-actual-emphasis);
						&:hover {
							background-color: var(--not-actual-emphasis-hover);
						}
					}
					.not-actual-heading {
						background-color: var(--not-actual-emphasis);
					}
					.expanded {
						max-width: 2000px;
					}
					.revision {
						text-align: right;
						min-width: $annual-grid-cell-width;
						cursor: pointer;
						padding-left: 5px;
						padding: 8px;
						.lines {
							// width: 80%;
							// text-align: right;
							margin-left: auto;
						}
						&:hover {
							background-color: var(--ace-neutral-200);
						}
					}
					.ass-edited {
						text-decoration: line-through;
					}
					.content-tab {
						background-color: var(--ace-warning-pressed);
						// padding-bottom: 40px;
						// padding-top: 40px;
						// height: 365px;
					}
					.content-tab.ng-enter {
						-webkit-transition: opacity 0.5s;
						transition: opacity 0.5s;
					}
					.year-detail {
						border: none;
						width: 80%;
						.content-tab.ng-enter {
							opacity: 0;
						}
						.content-tab.ng-enter-active {
							opacity: 1;
						}
						.content-tab.ng-leave {
							opacity: 1;
						}
						.content-tab.ng-leave-active {
							opacity: 0;
						}
					}
					.year-detail.ng-enter {
						-webkit-transition: opacity 0.5s;
						transition: opacity 0.5s;
					}
					.year-detail.ng-enter,
					{
						opacity: 0;
					}
					.year-detail.ng-enter-active {
						opacity: 1;
					}
					.year-detail.ng-leave {
						opacity: 1;
					}
					.year-detail.ng-leave-active {
						opacity: 0;
					}
					.clickable.ng-enter {
						-webkit-transition: opacity 0.5s;
						transition: opacity 0.5s;
					}
					.clickable.ng-enter,
					{
						opacity: 0;
					}
					.clickable.ng-enter-active {
						opacity: 1;
					}
					.clickable.ng-leave {
						opacity: 1;
					}
					.clickable.ng-leave-active {
						opacity: 0;
					}
					.heading {
						text-align: left;
						vertical-align: middle;
						min-width: 84px;
						white-space: nowrap;
					}
					.sum-label {
						border-top: 1px solid black;
					}
					.sum-figure {
						@extend .sum-label;
						margin-left: auto;
					}
					.cap-component {
						border-top: 1px dashed black;
					}
					.lines-active {
						cursor: pointer;
						text-align: right;
						&:hover {
							background-color: var(--ace-neutral-200);
						}
					}
					.year-appeal-data {
						&:hover {
							background-color: var(--ace-neutral-200);
						}
					}
				}
			}
		}
	}
}

.collapsed-appeal-info.ng-hide-remove {
	-webkit-transition: 0.5s linear all;
	transition: 0.5s linear all;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
	/* remember to add this */
	display: block!important;
	opacity: 1;
}

.collapsed-appeal-info.ng-hide {
	opacity: 0;
}

.col-lg-12 {
	-webkit-transition: 0.3s linear all;
	transition: 0.3s linear all;
}

.assessments {
	font-size: $assessment-panel-font-size;
	.dropdown-menu {
		min-width: 124px;
	}
	.assess {
		.assess-heading {
			margin-bottom: 0px;
			div {
				display: inline-block;
				vertical-align: middle;
			}
			.assess-title {
				font-family: $header-font;
				text-transform: uppercase;
				font-size: $assessment-panel-header-font-size;
			}
		}
		.assess-table {
			width: 100%;
			margin-bottom: 10px;
			td,
			th {
				padding: 5px;
				&:first-child {
					text-align: left;
				}
			}
			th {
				background-color: var(--ace-info);
				color: white;
				font-size: $table-header-font-size;
				text-align: right;
				span {
					margin-right: 8px;
				}
			}
			tbody {
				font-size: $assessment-panel-font-size;
				td,
				input {
					text-align: right;
				}
				.overridden {
					color: var(--ace-danger);
				}
				.exem {
					color: var(--ace-danger);
					input {
						color: var(--ace-danger);
					}
				}
				.total {
					td {
						padding: 5px 5px 20px 5px;
						font-weight: bold;
						border-top: 1px solid grey;
					}
				}
				.cap {
					td {
						padding: 10px 5px 5px 5px;
						font-weight: bold;
						border-top: 1px dashed black;
					}
				}
				.add-comp {
					td {
						padding-top: 40px;
					}
				}
			}
		}
	}
}

.tax-bills {
	text-align: left;
	white-space: nowrap;
	.tax-bill,
	.labels {
		display: inline-block;
		vertical-align: top;
		td,
		th {
			height: 30px;
		}
		td {
			text-align: right;
			padding: 0px 5px 0px 15px;
		}
	}
	.labels {
		font-weight: bold;
		padding-top: 3px;
	}
	.estimated-bill {
		background-image: url('../images/estimated-vertical.png');
		background-size: contain;
		background-repeat: no-repeat;
	}
	.tax-bill {
		margin-right: 10px;
		margin-bottom: 10px;
		padding: 3px;
		box-shadow: 2px 2px 5px var(--ace-neutral-500);
		border-left: 1px solid lightgray;
		border-top: 1px solid lightgray;
		border-bottom: 1px solid lightgray;
		th {
			text-align: center;
			vertical-align: bottom;
		}
		input[type=number] {
			text-align: right;
		}
		td {
			width: 153px;
			.checkbox {
				margin: 0;
			}
		}
		.red {
			input {
				color: var(--ace-danger);
			}
		}
		.no-height {
			height: 0;
		}
		.payment-cell {
			border-right: 1px dashed lightgray;
			padding-right: 8px;
			&:last-child {
				border-right: none;
				padding-right: 5px;
			}
		}
	}
}

.filing-due-date {
	.overridden-due-date {
		input, .weissman-datetime-picker {
			color: var(--ace-danger);
		}
	}
}

.bottom-band {
	width: 100%;
	border-top: 1px solid black;
	.calcs {
		margin-top: 10px;
		strong {
			margin-left: 20px;
			margin-right: 10px;
		}
	}
}

.panel-body-compact {
	height: 250px;
	overflow: auto;
}

.state-heading {
	padding-bottom: 0;
	div {
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
	}
	.alert {
		margin-bottom: 0;
	}
}

.contacts {
	table {
		width: 90%;
		td {
			&:first-child {
				text-align: left;
				width: 80px;
			}
			text-align: center;
		}
	}
}

.ass-coll {
	.details {
		.fields {
			td {
				vertical-align: middle;
				&:first-child {
					font-weight: bold;
				}
			}
			.abbr {
				.form-control {
					width: initial;
					display: inline-block;
				}
			}
		}
	}
}

.ass-class {
	td {
		vertical-align: middle !important;
	}
	.checkbox {
		margin-top: 0;
		margin-bottom: 0;
		.display-type {
			margin-left: 14px;
		}
	}
	.defaults {
		td {
			width: 50%;
		}
	}
	.ratios {
		.help-block {
			display: inline-block;
		}
		.ratio {
			margin-left: 5px;
			text-align: right;
		}
		.yearly-ratio {
			.table {
				width: 50%;
				th,
				td {
					&:nth-child(2) {
						text-align: right;
					}
				}
			}
		}
	}
}

.scenarios {
	td {
		&:first-child {
			width: 15%;
		}
	}
}

.scenario {
	.form-group {
		margin-bottom: 8px;
	}
	.bill {
		.option {
			.card-body {
				padding: 10px;
				.default-option {
					.radio {
						margin-right: 15px;
					}
				}
				.option-info {
					.form-horizontal {
						// .form-group {
						//   margin: 0 0 20px 0;
						//   label {
						//     padding-right: 5px;
						//     padding-left: 5px;
						//   }
						//   div {
						//     padding: 0;
						//   }
						// }
					}
				}
				.payment-labels {
					vertical-align: top;
					min-width: 130px;
					padding: 5px 0 0 25px;
					font-weight: bold;
					div {
						margin-bottom: 10px;
					}
				}
				.payments {
					max-width: 700px;
					min-width: 700px;
					overflow-x: scroll;
					.payment {
						min-width: 355px;
						max-width: 355px;
						.row {
							margin-left: 0;
							margin-right: 10px;
							> div {
								padding: 0;
							}
						}
						.accrual {
							input {
								display: inline-block;
								width: 55px;
							}
							select {
								display: inline-block;
								width: 103px;
							}
						}
					}
				}
			}
			.delete {
				padding: 0 7px;
			}
		}
	}
}

.class-anim.ng-hide-add,
.class-anim.ng-hide-remove {
	-webkit-transition: 0.5s linear all;
	transition: 0.5s linear all;
	/* remember to add this */
	display: block!important;
	// opacity: 1;
	overflow: hidden;
	max-height: 50px;
	height: 50px;
}

.class-anim.ng-hide {
	// opacity: 0;
	max-height: 0;
}

//
//d3
//
.bar {
	fill: rgb(104, 35, 45);
}

.bar:hover {
	fill: brown;
}

.axis {
	font: 12px sans-serif;
}

.axis path,
.axis line {
	fill: none;
	stroke: var(--ace-neutral-black);
	shape-rendering: crispEdges;
}

.x.axis path {
	display: none;
}

.line {
	fill: none;
	stroke: steelblue;
	stroke-width: 1.5px;
}

.arc path {
	stroke: var(--ace-neutral-white);
}

.std-dropdown {
	overflow-y: auto;
	overflow-x: hidden;
	width: auto;
	height: 250px;
}

a.disabled {
	color: var(--ace-neutral-300);
	cursor: default;
	pointer-events: none;
	text-decoration: none;
}

a.disabled-instance, p.disabled-instance {
	display: none !important;
}

button.disabled-instance {
	display: none;
}

.slide-up,
.slide-down {
	-webkit-transition: all linear 0.3s;
	transition: all linear 0.3s;
	position: relative;
}

.slide-down.ng-enter {
	opacity: 0;
}

.slide-down.ng-enter.ng-enter-active {
	opacity: 1;
}

.slide-up.ng-leave {
	opacity: 1;
	top: 0;
}

.slide-up.ng-leave.ng-leave-active {
	opacity: 0;
	top: -200px;
}


/* Absolute Center Spinner  - http://codepen.io/MattIn4D/pen/LiKFC   */

.loading {
	position: absolute;
	z-index: 999;
	height: 2em;
	width: 2em;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}


/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
	/* hide "loading..." text */
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.loading:not(:required):after {
	content: '';
	display: block;
	font-size: 10px;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	-webkit-animation: spinner 1500ms infinite linear;
	-moz-animation: spinner 1500ms infinite linear;
	-ms-animation: spinner 1500ms infinite linear;
	-o-animation: spinner 1500ms infinite linear;
	animation: spinner 1500ms infinite linear;
	border-radius: 0.5em;
	-webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
	box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}


/* Animation */

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.ng-invalid-date {
	border-color: var(--ace-danger);
}

//This cannot be a global class
// .ng-invalid {
// 	border-color: var(--ace-danger);
// }

/*.sd-reqiured.sd-pristine.ng-invalid {
	border-color: blue;
}*/

.login-panel {
	margin-top: 30px;
	border-radius: 6px;
	padding: 30px;
	background-color: white;
	box-shadow: var(--ace-shadow);
}

.browser-warning-box {
	margin-top: 20px;
	padding: 10px 20px;
	background-color: var(--ace-danger-hover);
	color: var(--ace-neutral-white);
}

.row.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
	padding-right: 0;
	padding-left: 0;
}

.not-actual {
	background-color: var(--not-actual-emphasis);
	&:hover {
		background-color: var(--not-actual-emphasis-hover);
	}
}

.not-actual-no-hover {
	background-color: var(--not-actual-emphasis) !important;

    td {
        --bs-table-bg-type: var(--not-actual-emphasis) !important;
    }
}

.text-aligh-right {
	text-align: right;
}

.text-aligh-center, .text-align-center {
	text-align: center;
}

.text-aligh-left {
	text-align: left;
}

.container-wide {
	margin: 0 auto;
	width: 90% !important;
}

.single-column-layout {
	margin: 0 auto 0px auto;
	background-color: var(--ace-neutral-white);
	min-height: 100%;
	padding: 0 30px 40px 30px;
	border-left: 1px solid var(--ace-neutral-300);
	border-right: 1px solid var(--ace-neutral-300);
	border-bottom: 1px solid var(--ace-neutral-300);
	h1 {
        display: inline-block;
		margin: 20px 0 1.25rem;
		font-family: "myriad-pro", "PT Sans", sans-serif;
        font-size: 1.563rem;
        line-height: 1.875rem;
		text-transform: uppercase;
	}
}

.modal {
    .weissman-datetime-picker {
        .weiss-datepicker-input {
            z-index: 1057;
        }
    }
}

.weissman-datetime-picker {
    display: inline-block;
    position: relative;

	.weiss-datepicker-time-input {
		width: 4.375rem;
        margin-left: 0.5em !important;
	}
	.weiss-datepicker-input {
		// https://stackoverflow.com/a/17727667
		position: relative;
		z-index: 999;
        display: table-cell;
        vertical-align: middle;
        width: 5.625rem;
	}
	.date-button {
		cursor: pointer;
        display: table-cell;
        vertical-align: middle;
	}

    .invalid {
		border-color: var(--ace-danger);
	}

	.input-group {
        input {
            height: 1.875rem;
            font-size: 0.75rem;
            flex: 0 1 auto;
        }

        .btn {
            color: var(--bs-body-color);
            border-color:  var(--bs-border-color);

            &:hover {
                color: var(--bs-white);
            }
        }
	}
}

.red-image {
	width: 20%;
	transform: rotate(330deg);
	right: 50px;
	position: absolute;
	opacity: 0.5;
}
.sold-img {
	@extend .red-image;
	margin-top: 99px;
}
.status-img {
	@extend .red-image;
	margin-top: 20px;
}

.main-header {
	font-family: $header-font;
	text-transform: uppercase;
}


/* In the email body we're using email-friendly html (with align and cellpadding attributes), but when rendered in Weissman
    some of the surrounding styling rules override those attributes. Sort of a hack, but specify the actual styling we want
    here to un-override the 1999-esque HTML attributes.
*/
div.email-preview-display table {
    td, th {
        padding: 3px;
        text-align: left;
    }
}

@-ms-viewport{width:auto!important}

.help-page {
	h2 {
		font-family: $header-font;
		text-transform: uppercase;
		font-size: 25px;
	}

	h4 {
		margin-bottom: 5px;
	}

	.row {
		padding-bottom: 20px;
	}

	.help-panel-content {
		margin-top:-16px;
		float: left;
		width: 65%;

		.indented-item {
			margin-left: 20px;
		}
	}

	.help-icon {
		float: left;
		margin-right: 10px;
	}

	.release-note-summary {
		border-top: solid 1px var(--ace-neutral-300);
		width: 100%;
		display: inline-block;
		padding: 10px;


		&:hover {
			background-color: var(--ace-neutral-050);
		}

		.note-title {
			font-family: 'Oswald';
			font-size: 18px;
		}

		.note-content {
			max-height: 100px;
			overflow: hidden;
			transition: max-height .5s ease;
		}

		.show-all {
			max-height: none;
		}

		i.fa-close {
			color: var(--ace-neutral-600);
		}

		.ace-btn {
			padding: 2px;
		}

	}

	.fade {
		background: linear-gradient(to bottom, rgba(var(--ace-neutral-white) ,0) 0%,rgba(var(--ace-neutral-white),1) 75%);
		height: 100px;
		margin-top: -100px;
		position: relative;
	}


}

.table tr td.change-history-more-row {
	cursor: pointer;
	text-align: center;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	color: white;
	background-color: var(--ace-neutral-300);
    --bs-table-bg-type: var(--ace-neutral-300);

	&:hover {
		background-color: var(--ace-neutral-500);
	}
}

.change-history-no-border {
	td {
		border-top: none !important;
	}
}

.assessment-taxes-details td.under-appeal,.under-appeal {
	background-color: var(--under-appeal);
}

.tax-rates-maximized {
	height: 640px;

	.ag-theme-balham {
		height: 100%;
	}
}

.filter-select {
    min-width: 200px;
    height: 100px !important;
    margin-left: 15px;

    @include multi-select;
}

.remove-panel {
	display: none !important;
}

.modal-change-history {
	width: 1100px;
	max-width: 1100px;
}

/*.modal-create-invoice {
	width: 1100px;
}*/

.repeating-ellipses:after {
	content: "...";
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	animation: ellipsis-dot 1s infinite .5s;
	animation-fill-mode: fowards;
	width: 1.25em;
  }

.scrollable-typeahead {
	.dropdown-menu {
		overflow-y: auto;
		max-height: 300px;
	}
}

// Hacky. We need a standard way to handle z-indices.
.modal-on-top {
	z-index: 1056 !important;
}
// Not sure why uibModal isn't handling this correctly but if there is a nested modal it doesn't get the z-index right.
.nested-modal {
    z-index: 1061 !important;
}

bs-dropdown-container {
	z-index: 99999 !important;
}

.bold-text {
	font-weight: bold;
}

// Hack to remove the close icon on the site map info window popup
.gm-ui-hover-effect {
    opacity: 0;
}

@keyframes ellipsis-dot {
	25% {
		content: "";
	}
	50% {
		content: ".";
	}
	75% {
		content: "..";
	}
	100% {
		content: "...";
	}
}

/* Bootstrap dropdowns appear to require anchor tags for styling, which is infuriatingly wrong-headed. We have bugs on parcel characteristics
    for any given value of href we try to use because again, these aren't links so shouldn't be anchor tags. The best I've come up with is
    to remove the href attribute and manually give the pointer cursor back to the tags. We could fix this by using a not-braindead css library,
    but that's an entire UI rewrite.
*/
.dropdown-cursor-fix a {
    cursor: pointer;
}

.disable-pointer-events {
	pointer-events: none !important;
}

/* Quill editor components don't come with a fixed height, which looks really flaky in a modal. Apply the quill-height-fix class to
any element above a quill editor to set the height to 250 px. */
.quill-height-fix .ql-container {
    height: 250px;
}

.modal-open-nested {
	overflow: hidden;
	.modal {
		overflow-x: hidden;
		overflow-y: auto;
	}
}

// Assessor/Collector list
.larger-letters {
	.nav-link {
		font-size: 150%;
	}
}
.larger-letters.disabled {
	cursor: not-allowed;
}

.ws-button-row {
    display: flex;
    gap: 5px;
    white-space: nowrap;
    align-items: center;
}

.ws-button-row-warning {
    width: 50%;
    text-align: left;
}

.ag-theme-balham .ag-icon-checkbox-unchecked-readonly {
    cursor: not-allowed !important;
}
