@import "../compliance/compliance-variables.scss";

entity-import-list {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 8px 0;
}

entity-import-process {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;

    button.warning-button {
        border-left: 1px solid var(--ace-neutral-300);
        padding-left: 8px;
    }

    .import-step-actions {
        position: relative;
        padding-bottom: 8px;
    }

    entity-import-editor {
        margin-top: 8px;
    }

    ag-grid-angular {
        .header-cell {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-align: center;
            margin: 0;

            &.mapping-required {
                color: var(--ace-neutral-white);
                background-color: var(--ace-info);
            }

            &.mapping-optional {
                color: var(--ace-neutral-white);
                background-color: var(--ace-info-ghosted);
            }

            &.mapping-unknown {
                color: var(--ace-neutral-white);
                background-color: var(--ace-danger);
            }
        }
    }

    .asset-info {
        div {
            font-weight: bold;
        }

        span {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

entity-import-column-mapping-step {
    ag-grid-mapping-header-row-cell-renderer {
        display: block;
        width: 100%;
        height: 100%;
    }

    position: relative;

    .available-fields {
        flex: none;

        .field-pills-wrapper {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            width: 100%;
            padding-left: 8px;
            max-height: 68px;
            overflow-y: auto;

            .field-pill {
                flex: none;
                color: var(--ace-neutral-white);
                padding: 4px 16px;
                margin: 4px;
                border-radius: 15px;
                cursor: pointer;

                &.field-pill-required {
                    background-color: var(--ace-info);
                }

                &.field-pill-optional {
                    background-color: var(--ace-info-ghosted);
                    color: var(--ace-info);
                }
            }
        }
    }

    .grid-wrapper {
        margin: 16px 0 8px 0;

        .grid {
            .header-cell {
                text-overflow: ellipsis;
                overflow: hidden;
                z-index: 1;

                &.unmapped {
                    z-index: 0;
                }

                .unassign-button {
                    font-weight: bold;
                    color: var(--ace-neutral-white);
                    vertical-align: unset;
                }
            }

            .pinned-cell {
                text-align: center;
                color: var(--ace-neutral-500);
                font-style: italic;
            }

            .static-column-cell {
                color: var(--ace-neutral-500);
            }
        }
    }
}

entity-import-editing-step {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    entity-import-validation-summary {
        flex: 2;
        max-height: 35%;
    }

    entity-import-editor {
        ag-grid-editing-header-row-cell-renderer {
            display: block;
            width: 100%;
            height: 100%;
        }

        flex: 5;
    }
}

entity-import-validation-summary {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    > small {
        margin-bottom: 10px;
    }

    .text-danger {
        color: var(--ace-danger);
    }

    .text-warning {
        color: var(--ace-warning);
    }

    .text-success {
        color: var(--ace-success);
    }

    .ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.cell-validation-error) {
            border: none;
    }

    ag-grid-angular {
        .ag-cell:focus {
            outline: none;
        }
    }

    .ag-theme-balham .cell-validation-error, .ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus.cell-validation-error {
        border-left-style: solid;
        border-left-color: var(--ace-danger);
        border-left-width: 8px;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
    }

    .ag-theme-balham .cell-validation-warning, .ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus.cell-validation-warning {
        border-left-style: solid;
        border-left-color: var(--ace-warning);
        border-left-width: 8px;
        border-bottom-style: none;
        border-top-style: none;
    }
}

entity-import-editor {
    display: flex;
    flex-flow: column;
    width: 100%;

    .ws-section__header__actions__item {
        div.checkbox {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    > small {
        margin-bottom: 10px;
    }

    .ag-theme-balham .row-transferred {
        color: var(--ace-neutral-300);

        .static-cell {
            color: var(--ace-neutral-300);
        }
    }

    .ag-theme-balham .ag-row-selected {
        .static-cell {
            color: var(--ace-neutral-500);
        }
    }

    .static-cell {
        color: var(--ace-neutral-500);
    }

    .ag-theme-balham .cell-validation-error {
        border-left-style: solid;
        border-left-color: var(--ace-danger);
        border-left-width: 6px;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
        background: var(--ace-danger-ghosted);
    }

    .ag-theme-balham .cell-validation-warning {
        border-left-style: solid !important;
        border-left-color: var(--ace-warning);
        border-left-width: 6px;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
        background: var(--ace-warning-ghosted);
    }

    ag-grid-angular {
        .ag-cell:focus {
            outline: none;
        }

        .ag-icon-checkbox-unchecked-readonly {
            cursor: not-allowed;
        }
    }

    .ws-calculated {
        background: none !important;
        border: none transparent !important;
    }
}

entity-import-upload {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .modal-dialog {
        overflow-y: initial !important
    }

    .modal-body {
        overflow-y: auto;
    }

    .form-group {
        padding: 1px;
        padding-bottom: 4px;
    }

    .import-file-info {
        padding: 0 8px;
        border-top: 1px solid var(--ace-neutral-300);
        background-color: var(--ace-neutral-050);

        .import-file-info-question {
            padding-left: 8px;
        }
    }
}

entity-import-static-fields {
    .values-container {
        margin-top: 3em;
    }
}

ag-grid-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .ag-grid-overlay {
        position: absolute;
        height: 100%;
        width: 100%;

        &__container {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            background-color: var(--ace-neutral-white);
            opacity: 0;

            &__icon {
                position: static;
                padding-top: 50px;
                text-align: center;
                color: var(--ace-neutral-800);
                font-weight: 700;
            }

            &--over {
                opacity: 0.9;
                border: 2px dashed var(--ace-neutral-800);
            }
        }
    }
}

.import-file-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.import-file-name {
    font-size: 14px;
    font-weight: bold;
}

import-bulk-update-modal {
    .bulk-update-info {
        color: var(ace-neutral-400);
    }

    .text-danger {
        color: var(--ace-danger);
    }
}

assessor-selector {
    div {
        width: 100%;
    }
}

entity-import-specification-list {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 8px 0;
}

attachment-type-selector {
    div {
        width: 100%;
    }
}

taxing-district-selector {
    div {
        width: 100%;
    }
}
