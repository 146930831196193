.roles {
	word-break: break-word;
	hyphens: auto;
	td,
	th {
		vertical-align: middle !important;
	}
	th {
		text-transform: capitalize;
	}
	input[type=checkbox].editable-input {
		position: inherit;
		margin-left: 0;
	}
}
