@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400italic,700italic,700);

//FONT DEFINITIONS
$panel-header-font: 'Oswald', Arial, sans-serif;
$header-font: 'Oswald', Arial, sans-serif;
$body-font: 'PT Sans', sans-serif;

//FONT SIZES
$panel-header-font-size: 17px;
$body-font-size: 12px;
$table-header-font-size: 16px;
$page-header-font-size: 36px;

$assessment-panel-font-size: 14px;
$assessment-panel-header-font-size: 20px;
