.tax-rate-modal {
    width: 90%;
    max-width: 90%;
}

tax-rates-panel {
    .disabled {
        color: var(--ace-neutral-500);
    }
}

.tax-rates-form-items {
    > * {
        padding: 0 10px;
    }

    label {
        margin-bottom: 0;
    }
}

.tax-authorities-popover {
    td, th {
        padding: 3px;

        &:last-child {
            text-align: right;
        }
    }
}

tax-authority {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    .panel-flat-color {
        margin: 15px !important;
    }

    .tax-authority-info {
        td {
            &:first-child {
                font-weight: bold;
                padding-right: 10px;
            }

            padding: 3px 0;
        }
    }

    .tax-authority-details {
        margin: 0;
        td, th {
            text-align: center;
            vertical-align: middle !important;
        }
    }

    .not-actual-tax-year {
        div, input {
            background-color: var(--ace-danger-ghosted);
        }
    }

    .pending-tax-year {
        div, input {
            background-color: var(--ace-focused-color);
        }
    }
}

.text-align-right {
	text-align: right;
	.ag-header-cell-label {
		justify-content: flex-end !important;
		text-align: right !important;
	}

}

.cc-bulk-update-fields {
    td {
        padding: 5px;
    }
}

.pending {
    background-color: var(--ace-focused-color);
}

.tax-rate-estimated {
    background-color: var(--not-actual-emphasis);
}

.ag-row-hover {
    .tax-rate-estimated {
        background-color: var(--ace-danger-ghosted);
    }

    .pending {
        background-color: var(--ace-focused-color);
    }
}

.rates-pending-qc {
    th {
        padding: 8px;
    }

    td {
        cursor: pointer;

        .badge {
            --ace-action: #5373b4;
            background-color: var(--ace-action);
        }
    }
}

.forecasting-budgeting-grid .ag-header-group-cell-label {
    justify-content: center;
}

.forecasting-budgeting-grid {
    .fmv-header {
        background-color: var(ace-warning-ghosted);
    }

    .tax-header {
        background-color: var(--ace-neutral-200);
    }

    //****
    //https://stackoverflow.com/questions/48962231/draw-triangle-in-corner-of-div
    //****
    .exemption {
        overflow: hidden;
    }

    .exemption:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 10px 10px;
        border-color: transparent transparent transparent var(--ace-danger);
        left: 0;
        top: 0;
        position: absolute;
    }
    //****
}

.accruals-page-grid {
    .open-tax-obligations-header {
        background-color: var(--ace-viz-zumthor);
    }

    .period-contributions-header {
        background-color: var(--ace-viz-spring-green);
    }

    .accrual-balances-header {
        background-color: var(--ace-viz-peach);
    }

    .journal-impact-header {
        background-color: var(--ace-viz-plum);
    }

    .journal-balances-header {
        background-color: var(--ace-neutral-200);
    }

    // .tax-header {
    //     background-color: var(--ace-neutral-200);
    // }

    .ag-header-group-cell-label {
        justify-content: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        white-space: pre-wrap !important;
    }

    .ag-header-cell::after {
        margin-top: 14px !important;
    }
}
