.property-chars-enabled {
	.editable-wrap {
		display: block;
		text-align: center;
	}
}

.red {
	color: var(--ace-danger);
}

.dropdown-descriptors {
	max-height: 300px;
	overflow-y: auto;
}

.editable-empty > i {
	color: var(--ace-neutral-700);
}

.entity-prop-char-panel {
	.panel-heading {
		padding: 0;

		panel-chevron {
			.fa {
				vertical-align: initial !important;
			}
		}
	}

	.card-body {
		padding: 5px 15px;
	}
}
