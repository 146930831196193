@import '../compliance/compliance-variables.scss';

reporting-parcel-site-list {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}

reporting-parcel-site-list .grid-wrapper, reporting-parcel-site-add .grid-wrapper {
    position: relative;
    overflow: hidden;
    min-height: 250px; //todo: remove this
    .grid {
        width: calc(100% - 4px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &.grid-cell-no-focus {
            .ag-cell-focus, .ag-cell-no-focus {
                border: none !important;
            }

            .ag-cell:focus {
                border: none !important;
                outline: none;
            }
        }

        grid-action-cell {
            position: absolute;
            left: 4px;
        }

        span.icon-indicator {
            margin-left: 6px;
            margin-right: 6px;
            text-align: center;
            color: var(--ace-neutral-700);
        }

        .ag-row-hover {
            button.grid-action-button {
                visibility: visible;
                display: inline-block;
                margin: 0 !important;
                z-index: 100;
            }

            span.icon-indicator {
                visibility: hidden;
                display: none;
            }
        }

        .grid-cell-readonly {
            color: var(--ace-neutral-300);

            a.grid-link-cell {
                color: var(--ace-neutral-300);
            }
        }

        .grid-row-deleted {
            color: var(--ace-neutral-300);

            a.grid-link-cell {
                color: var(--ace-neutral-300);
            }
        }

        a.grid-link-cell {
            text-decoration: none;
            color: var(--ace-neutral-700);
        }

        a.grid-link-cell:hover {
            text-decoration: underline;
            color: var(--ace-action-hover);
        }
    }
}
