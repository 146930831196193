$breadcrumb-divider: quote(">");

// Global color overrides
:root {
    --bs-blue: var(--ace-info) !important;
    --bs-primary: var(--ace-info) !important;
    --bs-body-color: var(--ace-neutral-700) !important;
}
.list-group {
    --bs-list-group-color: var(--ace-neutral-700) !important;
    --bs-list-group-active-bg: var(--ace-info) !important;
    --bs-list-group-active-border-color: var(--ace-info) !important;
}
.modal {
    --bs-modal-color: var(--ace-neutral-700) !important;
}
.btn {
    --bs-btn-border-radius: var(--ace-border-radius) !important;
}

// Style overrides
body {
    font-size: 0.875rem;
}

h1 {
    font-size: 2.25rem;
}

h2 {
    font-size: 1.875rem;
    margin: 20px 0 10px 0;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.125rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

h5 {
    font-size: 0.75rem;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
    font-weight: 400;
    line-height: 1;
    color: var(--ace-neutral-600);
    font-size: 65%;
}

h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
    font-size: 75%;
}

.text-muted {
    color: var(--ace-neutral-600) !important;
}

.lead {
    font-size: 1.25rem;
}

.input-group-text {
    font-size: 0.75rem;
}

.input-group {
    .btn {
        background-color: var(--bs-gray-100);
        color: var(--bs-body-color);
        border-color:  var(--bs-border-color);

        &:hover {
            color: var(--bs-btn-hover-color);
            background-color: var(--bs-btn-hover-bg);
            border-color: var(--bs-btn-hover-border-color);
        }
    }
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: inherit;
    font-size: inherit;
    line-height: inherit;
}

.form-select, .form-select-sm {
    color: var(--ace-neutral-700);

    &:focus-visible {
        outline: none;
        border-color: var(--ace-focused-color);
        box-shadow: var(--ace-focus-ring);
    }

    &:disabled {
        background-color: var(--ace-neutral-100);
        color: var(--ace-neutral-600);
    }
}

.container-fluid {
    padding: 0 15px;
}

.nav>li>a:hover {
    text-decoration: none;
}

.page-header {
    padding-bottom: 9px;
    margin: 2.5rem 0 1.25rem;
    border-bottom: 1px solid var(--ace-neutral-100);

    &.as-header {
        margin-top: 1.563rem;
    }
}

.form-control {
    font-size: 0.875rem;
    padding: 0.234rem .469rem;
    color: var(--ace-neutral-700);
}

.form-inline {
    .form-group, .form-select-sm {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
        width: auto;
    }

    .form-control {
        display: inline-block;
        vertical-align: middle;
    }
}

.checkbox, .radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    input[type=checkbox], input[type=radio] {
        position: absolute;
        margin-top: 4px;
        margin-left: -20px;
    }

    label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
    }

    +.radio, +.checkbox {
        margin-top: -5px;
    }
}

.radio-inline, .checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;

    input[type=radio], input[type=checkbox] {
        position: absolute;
        margin-top: 4px;
        margin-left: -20px;
    }

    +.radio-inline, +.checkbox-inline {
        margin-top: 0;
        margin-left: 10px;
    }
}

.table-bordered {
    border: 1px solid var(--ace-neutral-200);
}

.show:not(.btn-group) {
    display: block !important;
}

.open > .dropdown-menu {
    display: block;
    top: 100%;
}

.dropup.open > .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
}

// This can probably be removed once AngularJS UIB Bootstrap is gone
.dropdown-menu {
    font-size: 0.875rem;

    > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: var(--ace-neutral-700) !important;
        white-space: nowrap;
        text-decoration: none;
        cursor: pointer;
    }

    >.active>a, >.active>a:hover, >.active>a:focus {
        color: var(--ace-info) !important;
        text-decoration: none !important;
        background-color: var(--ace-info-secondary-hover);
        outline: 0;
    }

    >.disabled>a, >.disabled>a:hover, >.disabled>a:focus {
        color: var(--ace-neutral-600);
        cursor: default;
    }
}

.dropdown-menu-end {
    right: 0;
    left: auto;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropup .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px solid;
}

.dropdown-item.active {
    color: var(--ace-info);
    background-color: var(--ace-info-secondary-hover);
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.popover, .tooltip {
    position: absolute;
    font-size: 0.875rem;

    .arrow, .tooltip-arrow {
        position: absolute;
    }
}

.has-feedback {
    position: relative;

    .form-control {
        padding-right: 2.5rem;
    }
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    display: block;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    text-align: center;
    pointer-events: none;
}

.input-group {
    .form-control-feedback {
        right: 1.75rem;
    }
}

// UIB bootstrap overrides


.popover {
    >.arrow {
        border-width: 11px;
    }

    >.arrow, >.arrow:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    >.arrow:after {
        content: "";
        border-width: 10px;
    }

    >.arrow, >.arrow:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    &.fade {
        &.in {
            opacity: 1;
        }
    }

    &.top {
        margin-top: -10px;

        >.arrow {
            bottom: -11px;
            left: 50%;
            margin-left: -11px;
            border-top-color: var(--ace-neutral-500);
            border-top-color: rgba(0,0,0,.25);
            border-bottom-width: 0;
        }

        >.arrow:after {
            bottom: 1px;
            margin-left: -10px;
            content: " ";
            border-top-color: var(--ace-neutral-white);
            border-bottom-width: 0;
        }
    }

    &.top-left, &.top-right {
        >.arrow {
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
            margin: 0;
        }
    }

    &.bottom {
        margin-top: 10px;

        >.arrow {
            top: -11px;
            left: 50%;
            margin-left: -11px;
            border-top-width: 0;
            border-bottom-color: var(--ace-neutral-500);
            border-bottom-color: var(--ace-neutral-900)40;
        }

        >.arrow:before {
            border-color: transparent;
        }

        >.arrow:after {
            top: 1px;
            margin-left: -10px;
            content: " ";
            border-top-width: 0;
            border-bottom-color: var(--ace-neutral-white);
        }
    }

    &.end {
        margin-left: 10px;

        >.arrow {
            top: 50%;
            left: -11px;
            margin-top: -11px;
            border-right-color: var(--ace-neutral-500);
            border-right-color: rgba(0,0,0,.25);
            border-left-width: 0;
        }

        >.arrow:after {
            bottom: -10px;
            left: 1px;
            content: " ";
            border-right-color: var(--ace-neutral-white);
            border-left-width: 0;
        }
    }

    &.start {
        margin-left: -10px;

        >.arrow {
            top: 50%;
            right: -11px;
            margin-top: -11px;
            border-right-width: 0;
            border-left-color: var(--ace-neutral-500);
            border-left-color: var(--ace-neutral-900)40;
        }

        >.arrow:after {
            right: 1px;
            bottom: -10px;
            content: " ";
            border-right-width: 0;
            border-left-color: var(--ace-neutral-white);
        }
    }

    dt {
        width: 110px;
        float: left;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    dd {
        margin-left: 120px;
    }
}

.min-height-0 {
    min-height: 0 !important;
}

.text-warning {
    color: var(--ace-warning-pressed) !important;
}

.popover-content {
    padding: 9px 14px;
}

.toast-container {
    position: fixed;
}
.modal-header {
    padding: 15px;

    .modal-title {
        margin-top: 0;
    }

    .btn-close {
        box-sizing: border-box;
        width: 0.313rem;
        height: 0.313rem;
        margin: 0 0 0 auto;
    }
}

#ui-datepicker-div {
    z-index: 1057 !important;
}

/*ACE bootstrap overrides*/
.dropdown-content:not(.ngb-dp-body) {
    overflow: unset;
}
