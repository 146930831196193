expand-cell, expand-cell-header {
    .ag-row {
        div.tree-level-1 {
            padding-left: 10px;
        }

        div.tree-level-2 {
            padding-left: 30px;
        }

        div.tree-level-3 {
            padding-left: 50px;
        }
    }

    .expand-controls {
        width: 15px;
        display: inline-block;
    }

    .node-expanded, .node-collapsed {
        cursor: pointer;
        width: 14px;
        display: inline-block;
        color: var(--ace-neutral-500);
        font-weight: 900;
    }

    .node-collapsed:before {
        content: '\f054';
    }

    .node-expanded:before {
        content: '\f078';
    }
}
