@import "utilities/fonts.scss";

.comment-modal-body {
	min-height:200px;
    .comment-container {
        max-height: 38vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
	.list-group {
		border: solid 1px var(--ace-neutral-200);

		.list-group-item {

			padding: 8px;
			border: none;

			.comment-title {
				font-weight: bold;
			}

            .comment-description-container {
                .comment-description {
                    /* This probably isn't necessary, but we're going to use the line height to compute
                    how many lines are currently being displayed, so by setting the line height explicitly
                    here I hope to make this process more predictable and less likely to fail later. */
                    line-height: 1.5em;
                    white-space: pre-line;
                }
            }

			.comment-show-less {
			    height: 4.5em;
			    overflow-y: hidden;
			}

			.comment-date-stamp {
				font-style: italic;
				font-size: 12px;
			}

			.comment-author {
				font-size: 12px;
			}

			&.active {
				background-color: var(--ace-neutral-100);
				color: var(--ace-neutral-700);
				border-color: white;
			}

			&:first-child {
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;


			}

			&:last-child {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}
	}
}

.comments-dialog {
    z-index: 1100 !important; /*The uib component sets this in the element.  This is the only way to get the z-index above the rest*/
}
