div.manage-api-key-detail, div.manage-api-keys {
    span.validation-error {
        color: var(--ace-danger);
        font-weight: normal;
        margin-left: 1em;
    }
}

div.manage-api-key-detail {
    label {
        display: block;
    }
    .view-control,.both-control {
        font-weight: normal;
    }
    .title {
        display: inline-block;
        min-width: 6em;
    }
    table.user-table td {
        padding: 0.5em;
    }
    .edit-mode .view-control, .view-mode .edit-control {
        display: none;
    }
    .panel-flat-body {
        /* The user picker ends up underneath the bottom of the panel if we don't do this */
        overflow: inherit;
    }
}

div.manage-api-keys {
    label {
        display: block;
        .label-text {
            min-width: 8em;
            display:inline-block;
        }
        .form-input {
            font-weight: normal;
            min-width: 14em;
            max-width: 22em;
            display:inline-block;
        }
        input.form-input {
            padding: 3px 8px;
        }
    }
}

login-manager {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    overflow: auto;

    .ace-btn.login-manager-inline-button {
        margin-left: 1em;
    }

    input.iam-id {
        min-width:20em;
    }

    .login-manager-page-row {
        min-height: 315px;

        .login-manager-page-flex-column-left {
            flex: 1 0 50%;
            order: 1;
            padding: 8px;
        }

        .login-manager-page-flex-column-right {
            flex: 1 0 50%;
            order: 2;
            padding: 8px;
        }
    }
}
