.contract-terms {
    .contract-terms-edit {
        input.form-control {
            text-align: right;
        }

        .form-control {
            display: inline-block;
        }

        select {
            width: auto;
        }

        .contract-currency {
            width: 103px;
        }
    }

    // .contract-terms-view, .contract-terms-edit {
    //     td {
    //         &:nth-child(1) {
    //             width: 10%;
    //         }
    //         &:nth-child(2) {
    //             width: 20%;
    //         }
    //         &:nth-child(3) {
    //             width: 10%;
    //         }
    //         &:nth-child(4) {
    //             width: 30%;
    //         }
    //         &:nth-child(5) {
    //             width: 15%;
    //         }
    //         &:nth-child(6) {
    //             width: 10%;
    //         }
    //         &:nth-child(7) {
    //             width: 4%
    //         }
    //     }
    // }
}

.contract-terms-site {
    .not-site-default {
        color: var(--ace-danger);
    }

    select {
        width: auto;
        display: inline-block;
    }
}
