@import 'utilities/fonts.scss';

/*sliding animation for search panel*/

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(100%);
    }
}

.search-section {
    z-index: 1055;
    position: fixed;
    height: 100%;
    width: 35%;
    right: 0;
    top: 0;
    bottom: 0;
    /*transition: all 200ms ease-in;*/
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    box-sizing: border-box;
}

.search-section.show {
    /*left: 0;*/
}

.search-section .search-minimized {
    display: none;
}

.search-section .search-panel {
    border: none;
    border-radius: 0px;
    z-index: 1055;
    height: 100%;

    .card-header {
        padding: 5px;
        height: 36px;
        background-color: var(--ace-neutral-200);
        color: var(--ace-neutral-800);
        border: none;
        border-radius: 0;

        .card-title {
            font-size: 1rem;
        }

        .col-md-3 {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.search-section .search-heading {
    padding-right: 0;
    font-size: large;
}

.search-section .tooltip {
    z-index: 9998;
}

.search-section .search-body {
    z-index: 9997;
    padding: 0;
    height: 95%;
}

.search-overlay {
    /*display: none;*/
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    position: fixed;
    z-index: 1054;
    background-color: var(--ace-neutral-black);
    opacity: 0.5;
    overflow: hidden;
    box-sizing: border-box;
}

.search-actions {
    padding-left: 0;
}

.search-hide {
    z-index: 9999;
    position: absolute;
    top: 0;
    right: 10px;
    margin-top: 11px;
}

.search-new {
    z-index: 9999;
    position: absolute;
    top: 0;
    right: 38px;
    margin-top: 11px;
}

.search-result-default {
}

.search-result-default > .card-body {
    padding: 0;
    height: 100%;
}

.clickable {
    cursor: pointer;
}

.search-criteria-container {
    z-index: 9997;
    display: none;
    /*34.8%;*/
}

.search-results-container {
    z-index: 9996;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.search-spinner {
    position: absolute;
    top: 0;
    background-color: var(--ace-neutral-300);
    opacity: 0.5;
    width: 100%;
    height: 100%;

    div {
        position: relative;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
    }
}

.search-results-infinite {
    height: 99%;
    margin: 0;
    padding-top: 5px;
    border: none;
}

.search-scroll {
    border: 1px solid lightgrey;
    margin: 0;
    height: 97%;
    overflow-y: auto;

    .list-group {
        margin-bottom: 0;

        .list-group-item {
            padding: 5px 15px;

            .list-group-item-heading {
                margin-bottom: 0;
                font-weight: bold;
            }

            .search-result-parcel {
                width: 100%;

                .result-parcel-prop-type {
                    width: 1px;
                }
            }
        }
    }
}

.unsupported-filter {
    color: var(--ace-danger);
}

@mixin status-fields($padding-right) {
    width: 100%;
    text-align: right;
    position: absolute;
    top: 0;
    right: $padding-right;
    padding-top: 20px;
    .status-img {
        width: 75px;
        margin-top: 0;
        opacity: 0.6;
        transform: rotate(330deg);
    }
}

.result-content {
    position: relative;
    z-index: 100;
}

.parcel-status-fields {
    @include status-fields(40px);
}

.site-status-fields {
    @include status-fields(15px);
}

.company-status-fields {
    @include status-fields(15px);
}

.task-av-loader {
    margin-top: 45px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}

/*.ag-body-viewport-wrapper{
	//height: 300px !important;
	max-height: 600px !important;
	overflow-y:auto !important;
}*/

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
ul[ws-dnd-list] {
    min-height: 22px;
    padding-left: 0px;
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
ul[ws-dnd-list] .dndDraggingSource {
    display: none;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
ul[ws-dnd-list] .dndPlaceholder {
    display: block;
    background-color: var(--ace-neutral-200);
    min-height: 22px;
}

/**
 * Show selected elements in green
 */
ul[ws-dnd-list] li.selected {
    background-color: var(--ace-success-secondary-hover);
    color: var(--ace-success);
}
