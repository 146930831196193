valuation-panel {
    income-statement-list,
    rent-roll-list {
        display: flex;
        flex-flow: column;
        height: 100%;
        max-height: 100%;
        width: 100%;
    }
}

income-statement-list,
rent-roll-list {
    & > div {
        max-height: 100%
    }

    .grid-tools-panel {
        margin-bottom: 16px;
        overflow: auto;
    }

    .ws-section__filters__filter-group {
        label {
            white-space: nowrap;
        }
    }

    weissman-datetime {
        display: flex;

        .form-control {
            display: flex;
        }

        .input-group-addon {
            display: flex;
            border: none;
            background: none;
            padding-right: 0;
            padding-left: 0;
            margin-right: 0;
            margin: 0;
        }
    }

    .grid-wrapper {
        margin-bottom: 8px;

        .ag-layout-normal .ag-floating-bottom {
            position: static;

            .ag-row {
                background: var(--ace-neutral-100);
            }

            .totals-row, .selected-totals-row {
                .ag-cell.ag-cell-with-height.ag-cell-value:empty {
                    background: var(--ace-neutral-100);

                    &::after {
                        content: '-';
                    }
                }
            }

            .totals-row {
                .ag-cell.ag-cell-with-height.ag-cell-value:not(:empty) {
                    background: var(--ace-neutral-100);
                }
            }

            .ag-floating-bottom-viewport {
                .selected-totals-row {
                    .ag-cell.ag-cell-with-height.ag-cell-value:not(:empty) {
                        background: var(--ace-neutral-200);
                    }
                }
            }
        }
    }

    .ag-theme-balham .cell-validation-error {
        border-left-style: solid;
        border-left-color: var(--ace-danger);
        border-left-width: 8px;
        border-bottom-style: none;
        border-top-style: none;
        border-right-style: none;
    }
}
