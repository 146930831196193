:root, .ace-con {
    --ace-neutral-white: #ffffff;
    --ace-neutral-050: #f8f8fa;
    --ace-neutral-100: #efeff3;
    --ace-neutral-200: #d2d3dc;
    --ace-neutral-300: #a3a4b2;
    --ace-neutral-400: #8e90a0;
    --ace-neutral-500: #737585;
    --ace-neutral-600: #626374;
    --ace-neutral-700: #3e4050;
    --ace-neutral-800: #2b2c36;
    --ace-neutral-900: #16161b;
    --ace-neutral-black: #000000;
    --ace-neutral-opaque: rgba(86, 87, 103, 0.5);
    --ace-font-family: myriad-pro, Lato, Arial, sans-serif;
    --ace-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    --ace-focus-ring: 0 0 0 1px var(--ace-neutral-white), 0 0 0 0.25rem var(--ace-focused-color);
    --ace-focus-ring-inset: inset 0px 0px 0px 0.25rem var(--ace-focused-color);
    --ace-body-bg: var(--ace-neutral-white);
    --ace-body-color: var(--ace-neutral-700);
    --ace-border-color: var(--ace-neutral-200);
    --ace-disabled-color: var(--ace-neutral-300);
    --ace-secondary-text-color: var(--ace-neutral-600);
    --ace-form-border-color: var(--ace-neutral-300);
    --ace-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
    --ace-shadow-small: 0 0 4px rgba(0, 0, 0, 0.24);
    --ace-shadow-symmetric: 0 0 6px rgba(0, 0, 0, 0.24);
    --ace-action: #0a8287;
    --ace-action-hover: #065458;
    --ace-action-pressed: #032728;
    --ace-action-secondary-hover: #f1fdfe;
    --ace-action-secondary-pressed: #e3fcfd;
    --ace-action-ghosted: rgb(194, 224, 225);
    --ace-info: #0e247e;
    --ace-info-hover: #091750;
    --ace-info-pressed: #040a22;
    --ace-info-secondary-hover: #f1f4fd;
    --ace-info-secondary-pressed: #e3e8fc;
    --ace-info-ghosted: rgb(195, 200, 223);
    --ace-success: #217e38;
    --ace-success-hover: #165626;
    --ace-success-pressed: #0c2d14;
    --ace-success-secondary-hover: #f3fcf5;
    --ace-success-secondary-pressed: #e7f9eb;
    --ace-success-ghosted: rgb(200, 223, 205);
    --ace-warning: #edae49;
    --ace-warning-hover: #e8991b;
    --ace-warning-pressed: #bd7c13;
    --ace-warning-secondary-hover: #fef9f1;
    --ace-warning-secondary-pressed: #fcf3e3;
    --ace-warning-ghosted: rgb(251, 235, 210);
    --ace-danger: #cc2936;
    --ace-danger-hover: #a2202b;
    --ace-danger-pressed: #771820;
    --ace-danger-secondary-hover: #fcf2f3;
    --ace-danger-secondary-pressed: #fae6e7;
    --ace-danger-ghosted: rgb(242, 202, 205);
    --ace-focused-color: #a8d2f0;
    --ace-border-radius: 4px;
    --ace-tertiary-color: #edae49;

    // Visualization Colors
    // IMPORTANT: These are for data visualization and not for general use

    --ace-viz-pansy: #8D003F;
    --ace-viz-cherry-blossom: #F892B4;
    --ace-viz-plum: #E2AEE2;
    --ace-viz-steel-pink: #BD2CBC;
    --ace-viz-electric-indigo: #760FF0;
    --ace-viz-deep-indigo: #4D1988;
    --ace-viz-trypan-blue: #3C18D3;
    --ace-viz-state-blue: #7A5AF7;
    --ace-viz-zumthor: #A6CAFF;
    --ace-viz-blue-gray: #92B4D6;
    --ace-viz-ultramarine: #0633EB;
    --ace-viz-lochmara: #0083CB;
    --ace-viz-cerulean: #008EED;
    --ace-viz-neon-teal: #01E6AA;
    --ace-viz-spring-green: #90E78C;
    --ace-viz-bright-green: #55D43A;
    --ace-viz-olive: #727814;
    --ace-viz-pear-green: #C8D23D;
    --ace-viz-key-lime: #DEE480;
    --ace-viz-lemon: #FFE100;
    --ace-viz-Mango: #E7C12D;
    --ace-viz-chocolate: #774214;
    --ace-viz-light-brown: #B75E1D;
    --ace-viz-red-orange: #D14700;
    --ace-viz-tangerine: #FF903E;
    --ace-viz-peach: #FBB271;
    --ace-viz-coffee: #6B5F5F;

    // PP specific DO NOT REPLACE
    --not-actual: #FADDFA;
    --not-actual-emphasis: #FADDFA;
    --not-actual-emphasis-hover: #FFD6E0;
    --under-appeal: #90E78C;
}

.scrollable {
    overflow-y: auto;
    max-width: 100%;
    max-height: 100%;
}

.no-overflow-x {
    overflow-x: hidden;
}

// option
.option:not(:disabled):not(.disabled):active, .option:not(:disabled):active {
    color: inherit;
    background: var(--ace-neutral-100);
    box-shadow: var(--ace-focus-ring);
    z-index: 1;
}

.option:not(:disabled).active {
    z-index: 1;
    background-color: var(--ace-action);
    color: var(--ace-neutral-white);
}

.option:not(:disabled):hover:not(.active):not(:active) {
    background: var(--ace-neutral-100);
    color: inherit;
}

.option {
    box-shadow: none;
    border: none;
    background: none;
    user-select: none;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--ace-body-color);
    font-family: var(--ace-font-family);
    font-weight: 400;
    font-size: 1rem;
    white-space: nowrap;
    line-height: inherit;
    padding: 0;
    margin: 0;
    outline: none;
}
.option > .content {
    margin: 0.75rem 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.option:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.option:not(:disabled):not(.disabled):hover .radio::before {
    border-color: var(--ace-info);
}
.option:disabled {
    color: var(--ace-disabled-color);
}

.option {
    display: flex;
    flex-direction: column;
    color: var(--ace-neutral-700);
    align-items: flex-start;
    line-height: 20px;
    padding: 12px;
}
.option > .content {
    margin: inherit;
}
.option:not(:disabled):not(.disabled):active, .option.active {
    background-color: var(--ace-action);
    color: var(--ace-neutral-white);
}
.option:not(:disabled):not(.disabled):active > .desc, .option.active > .desc {
    color: var(--ace-neutral-white);
}

// Main UI view for hybrid router output
ui-view {
    ui-view-ng-upgrade {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        ui-view {
            display: flex;
            flex-grow: 1;
            padding-top: 0.25rem;

            &> :first-child {
                width: 100%;
            }
        }
    }
}

.ace-modal-header {
    padding: 8px 8px 0 8px !important;  // !important temporary until we remove previous styling from modal wrapper
    flex-direction: column;
}
.ace-modal-header-contents {
    padding: 0 8px !important;
    border-bottom: none !important;  // temporary
}
.ace-modal-title-container {
    flex: 1;
    align-items: center;
}
.ace-modal-title {
    font-family: 'myriad-pro';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #3E4050;
    // height: 32px;
    text-align: center;
}

.ace-modal-body {
    padding: 16px 24px 32px !important;
    font-family: 'myriad-pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.ace-modal-close-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 8px;
    width: 100%;

    .drag-header {
        flex: 1;
    }
}

.ace-modal-close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.ace-modal-close-text {
    font-family: 'myriad-pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2px;  // feels like a hack
    color: #3E4050;
}

.ace-modal-close-icon {
    /* Icon */

    width: 11px;
    height: 11.21px;
    margin: 6.5px;
    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.ace-modal, .ace-modal-dialog {  // make more global later
    .modal-content {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
        border-radius: 4px;
    }
}

.ace-button-container {
    button {
        border-radius: 4px;
        font-weight: 600;
        text-transform: uppercase;
    }
}
