@import 'utilities/mixins.scss';

.move-copy {
    .panel-flat-body {
        min-height: 355px;
        padding-bottom: 0 !important;
    }
    .site-list {
        @include multi-select;
        height: 133px !important;
        width: 300px;
        overflow-x: scroll;

        option:checked {
			box-shadow: 0 0 10px 10px var(--ace-focused-color) inset !important;
        }
    }

    label {
        width: 105px;
        text-align: right;
        margin-right: 5px;
    }

    .multi-select {
		@include multi-select;
        margin-bottom: 0 !important;
    }
}
