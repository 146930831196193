.allocation-asset-group {
    background-color: var(--ace-focused-color);
}

.allocation-wp-group {
    background-color: var(--ace-danger-ghosted);
}

.allocation-leasing-group {
    background-color: lightgreen;
}

.allocation-inventory-group {
    background-color: var(ace-warning-ghosted);
}

.allocation-details .grid-wrapper {
    .ag-theme-balham.allocation-assessments-theme {
        background-color: transparent;

        .ag-root {
            border: none;

            .ag-header {
                border: 1px solid var(--ace-neutral-200);

                .ag-header-row {
                    grid-action-cell-header {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 100%;
                        height: 25px;

                        .summary-action-button {
                            width: 20px;
                            height: 20px;
                            padding: 0 4px;
                            color: var(--ace-neutral-700);
                            transition: color 200ms ease;

                            &.active {
                                color: var(--ace-focused-color);
                            }

                            &.disabled {
                                color: var(--ace-neutral-300);
                                cursor: default;
                            }
                        }
                    }
                }
            }

            .ag-row {
                margin-top: 4px;
                max-height: 35px;
                padding: 4px 0;
                box-shadow: 1px 1px 3px rgba(0,0,0,10%);

                &:not(.ag-row-first) {
                    border: none;
                }

                &.ag-row-hover {
                    background-color: white;
                    box-shadow: 0 0 3px rgba(0,0,0,5%);
                }

                &.estimated-summary {
                    background-color: var(--not-actual-emphasis);
                    color: var(--ace-neutral-800);
                }

                &.phantom-summary {
                    background-color: var(--ace-neutral-200);
                    color: var(--ace-neutral-800);

                    .summary-title:hover {
                        .title {
                            background: var(--ace-neutral-200);
                        }
                    }
                }

                &.has-active-appeal {
                    background-color: var(--under-appeal);
                    color: var(--ace-neutral-800);
                }

                &.dragging {
                    padding: 0;

                    .ag-cell.ag-cell-with-height {
                        height: 100%;
                        padding: 0;
                    }
                }

                .ag-cell {
                    display: flex;
                    align-items: center;

                    &.ag-cell-with-height {
                        height: auto;
                    }

                    ag-grid-multi-select-cell {
                        height: 25px;
                    }

                    grid-action-cell {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 100%;
                        height: 25px;

                        .summary-action-button {
                            width: 20px;
                            height: 20px;
                            padding: 0 4px;
                            color: var(--ace-neutral-700);
                            transition: color 200ms ease;

                            .fa-lock {
                                color: var(--ace-success);
                            }

                            &.delete-button:not(.disabled):hover {
                                color: var(--ace-danger);
                            }

                            &.lock-button:not(.disabled):hover {
                                color: var(--ace-success);
                            }

                            &.info-button {
                                &:hover, &.active {
                                    color: var(--ace-focused-color);
                                }
                            }

                            &.disabled {
                                color: var(--ace-neutral-300);
                                cursor: default;
                            }
                        }
                    }
                }
            }

            .ag-pinned-left-cols-container, .ag-pinned-left-floating-bottom {
                .ag-row {
                    border-radius: 5px 0 0 5px;
                }
            }

            .ag-pinned-right-cols-container, .ag-pinned-right-floating-bottom {
                .ag-row {
                    border-radius: 0 5px 5px 0;
                }
            }

            .ag-floating-bottom {
                .ag-row {
                    background: white;
                }

                .ag-cell.ag-cell-with-height.ag-cell-value:empty {
                    background: white;

                    &::after {
                        content: '-';
                    }
                }
            }
        }
    }
}
