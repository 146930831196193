.preview-form-viewer {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: var(--ace-shadow);

    .apply-form-overrides {
        background-color: var(--ace-neutral-100);
    }

    .pdf-field-overrides {
        position: absolute;
        z-index: 10;
        background: var(--ace-tertiary-color);
        padding: 5px;
        right: 18px;
        top: 44px;
        width: 160px;
        max-height: 150px;
        overflow-y: auto;
        border: 1px solid var(--ace-danger);
        border-radius: 3px;
        box-shadow: -2px 2px 8px rgba(0, 0, 50, 0.4);

        div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
        }

        h4 {
            margin-top: 2px;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
        }
    }

    .pdf-host-empty {
        flex: 1 1 auto;
        text-align: center;
        padding-top: 32px;
        color: rgba(204,204,204, 0.75);
    }

    .pdf-host-render {
        flex: 1 1 auto;
        overflow: auto;
        position: relative;
        background: var(--ace-neutral-800);

        .form-output-wrapper {
            position: absolute;
            width: 100%;
            overflow: visible;

            &.hide-field-location #return-output .fieldInfo {
                display: none;
            }

            &.hide-field-location #appeal-output .fieldInfo {
                display: none;
            }

            #return-output,
            #appeal-output {
                position: relative;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow: visible;
                text-align: center;

                .page {
                    display: inline-block;
                    margin: 10px;
                }

                .page::after {
                    content: "\A";
                    white-space: pre;
                }
            }

            .annotationLayer .textWidgetAnnotation .pdf-modified-field {
                background: var(--ace-tertiary-color);
                /* pdf.js adds color to the element style, so we have to use !important to override that. */
                color: var(--ace-danger) !important;
            }

            .overrideInfo, .fieldInfo {
                width: 15px;
                height: 15px;
                position: absolute;
                display: block;
                font-size: 10px;
                padding-top: 1px;
                padding-left: 5px;
                color: var(--ace-danger);
                left: -14px;
                top: -5px;
                box-shadow: 1px 2px 8px rgba(0, 0, 50, 0.7);
                border-radius: 8px;
                font-weight: bold;

                &:hover {
                    cursor: pointer;
                }

                &.saved {
                    background: yellow;
                }

                &.unsaved {
                    background: var(--ace-focused-color);
                }
            }

            .fieldInfo {
                background: var(--ace-neutral-100);
                border: 1px solid var(--ace-neutral-300);
                box-shadow: 1px 2px 4px rgba(0, 0, 50, 0.8);
                color: blue;
            }
        }
    }

    .override-listing {
        .number {
            color: var(--ace-danger);
            font-weight: bold;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
