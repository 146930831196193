$ag-icons-path: '../../images/ag-grid-icons/';

@import '../utilities/layouts.scss';
@import "../compliance/compliance-variables.scss";

body > typeahead-container.dropdown.open {
    z-index: 2000;
}

/** Gray out checkbox label if the checkbox is disabled. **/
div.checkbox.disabled > label {
    color: var(--ace-neutral-300);
}

.ws-section {
    display: flex;
    flex-flow: column;
    margin: 0 0 8px 0;
    flex: none;

    &__header {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row;
        width: 100%;
        border-bottom: 1px solid var(--ace-neutral-200);
        padding-bottom: 4px;

        &__title {
            flex: 1 1 auto;
            margin-bottom: 0;
            padding-bottom: 0;
            font-family: "myriad-pro", "PT Sans", sans-serif;
            font-size: 16px;

            &.page-title {
                font-size: 1.563rem;
                margin-top: 6px;
            }
        }

        &__actions {
            flex: none;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;

            &__item {
                flex: none;
                display: flex;
                flex-flow: row;
                align-self: center;
                align-items: center;

                &:not(:first-child):not(.no-gap) {
                    border-left: 1px solid var(--ace-neutral-300);
                    padding-left: 8px;
                }
            }
        }
    }

    &__description {
        flex: none;
        font-size: small;
    }

    &__filters {
        flex: none;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 4px 0;

        &__filter-group {
            flex: none;
            display: flex;
            flex-flow: row;
            padding-right: 30px;
            align-items: center;

            label {
                margin: 0;
                padding-right: 4px;
                font-size: small;
            }
        }
    }
}

/** Overwrite tab styles to display content in a flex layout **/
.ws-tabset-flex {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;

    ul.nav {
        flex: none;
    }

    div.tab-content {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;

        tab {
            flex: 1 1 auto;
            position: relative;
        }
    }
}

/** A wrapper for all entry level components that the router renders as a distinct page in the application */
.ws-page {
    &.ws-page-top-padding {
        padding-top: $page-top-padding;
    }

    &.ws-page-bottom-padding {
        padding-bottom: $page-bottom-padding;
    }

    &.ws-page-vertical-padding {
        padding-top: $page-top-padding;
        padding-bottom: $page-bottom-padding;
    }

    &.ws-grid-page {
        display: flex;
        flex-flow: column;
        width: 100%;
        flex: 1 0 0;
        overflow: hidden;
    }
}

.weissman-datetime-wrapper {
    width: 1%;
}

@media (min-width: 768px) {
    .modal-xl {
        width: 90%;
        max-width: 1200px;
    }
}

.modal-no-max-width.modal-xl {
    width: 90%;
    max-width: 100%;
}

.app-ui-view .grid-wrapper {
    position: relative;
    overflow: hidden;

    .grid {
        width: calc(100% - 4px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &.grid-cell-no-focus {

            .ag-cell-focus, .ag-cell-no-focus {
                border: none;
            }

            .ag-cell:focus {
                border: none;
                outline: none;
            }

            .ag-cell.ag-cell-last-left-pinned {
                border-right: 1px solid var(--ace-neutral-200) !important;
            }

            .ag-cell.ag-cell-first-right-pinned {
                border-left: 1px solid var(--ace-neutral-200) !important;
            }
        }

        grid-action-cell {
            position: absolute;
            left: 4px;
        }

        button.grid-action-button {
            visibility: hidden;
            display: none;
        }

        div.grid-action-button-wrapper {
            display: inline-block;
            margin: 0;
            padding: 0;
        }

        span.icon-indicator {
            margin-left: 6px;
            margin-right: 6px;
            text-align: center;
            color: var(--ace-neutral-700);
        }

        .ag-row-hover {
            button.grid-action-button {
                visibility: visible;
                display: inline-block;
            }

            span.icon-indicator {
                visibility: hidden;
                display: none;
            }
        }

        .grid-cell-readonly {
            text-decoration: none;
            color: var(--ace-neutral-700);
            cursor: default;
        }

        .grid-row-deleted {
            color: var(--ace-neutral-300);

            a.grid-link-cell {
                color: var(--ace-neutral-300);
            }
        }

        a.grid-link-cell {
            text-decoration: none;
            cursor: pointer;
        }

        a.grid-link-cell:not(.grid-cell-readonly):hover {
            text-decoration: underline;
            color: var(--ace-action-hover);
        }

        span.grid-link-cell:not(.grid-cell-readonly):hover {
            text-decoration: underline;
            color: var(--ace-action-hover);
            cursor: pointer;
        }
    }
}

// *************************************************************************
// Hopefully temporary until we redo the contact modal
// *************************************************************************
company-permission-user-panel .grid-wrapper {
    .grid {
        &.grid-cell-no-focus {

            .ag-cell-focus, .ag-cell-no-focus {
                border: none;
            }

            .ag-cell:focus {
                border: none;
                outline: none;
            }

            .ag-cell.ag-cell-last-left-pinned {
                border-right: 1px solid var(--ace-neutral-200) !important;
            }

            .ag-cell.ag-cell-first-right-pinned {
                border-left: 1px solid var(--ace-neutral-200) !important;
            }
        }

        grid-action-cell {
            position: absolute;
            left: 4px;
        }

        button.grid-action-button {
            visibility: hidden;
            display: none;
        }

        div.grid-action-button-wrapper {
            display: inline-block;
            margin: 0;
            padding: 0;
        }

        span.icon-indicator {
            margin-left: 6px;
            margin-right: 6px;
            text-align: center;
            color: var(--ace-neutral-700);
        }

        .ag-row-hover {
            button.grid-action-button {
                visibility: visible;
                display: inline-block;
            }

            span.icon-indicator {
                visibility: hidden;
                display: none;
            }
        }

        .grid-cell-readonly {
            text-decoration: none;
            color: var(--ace-neutral-700);
            cursor: default;
        }

        .grid-row-deleted {
            color: var(--ace-neutral-300);

            a.grid-link-cell {
                color: var(--ace-neutral-300);
            }
        }

        a.grid-link-cell {
            text-decoration: none;
            cursor: pointer;
        }

        a.grid-link-cell:not(.grid-cell-readonly):hover {
            text-decoration: underline;
            color: var(--ace-action-hover);
        }

        span.grid-link-cell:not(.grid-cell-readonly):hover {
            text-decoration: underline;
            color: var(--ace-action-hover);
            cursor: pointer;
        }
    }
}

.no-selection, .no-data {
    height: 100%;
    width: 100%;
    line-height: 100%;
    vertical-align: middle;
    text-align: center;
}

.standard-control-separation {
    margin-left: $heading-control-separation;
}

entity-picker {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    .modal-body {
        height: 455px;

        ws-ag-grid-angular {
            display: block;
            height: 344px;
        }
    }

    .modal-options {
        padding: 15px 15px 0 15px;
    }
}

.ws-section__header__actions__item {
    margin-left: 8px;
}

.ws-expand-button {
    width: 25px;
    text-align: center;

    button {
        padding: 0 4px;
        color: var(--ace-neutral-700);
        font-size: 10px;
        background-color: transparent;
        font-weight: 900;
        border: none;
        display: inline-block;
        outline: 0;
    }
}

.ws-override {
    color: var(--ace-danger);

    input {
        color: var(--ace-neutral-black);
    }

    a.grid-link-cell {
        color: var(--ace-danger);
    }

    span.grid-link-cell {
        color: var(--ace-danger);
    }
}

.ws-calculated {
    --ace-action: #5373b4;
    color: var(--ace-action);
    font-weight: 700;
}

.ws-picker-tree {
    height: 400px;
    overflow: hidden;
    overflow-y: auto;

    ul {
        list-style: none;
        padding: 0;
    }

    .entity-link:hover {
        cursor: pointer;
        color: var(--ace-info-hover);
    }

    .active-entity-link {
        font-weight: bold;
        color: var(--ace-info);
    }

    .tree-line-holder {
        padding-left: 20px;
        height: 22px;
        border-bottom: 1px solid var(--ace-neutral-100);
        font-size: 16px;
        line-height: 22px;
    }

    .tree-line-holder.tree-line-level-2 {
        padding-left: 48px;
    }

    .tree-line-holder.tree-line-level-3 {
        padding-left: 88px;
    }

    .tree-line-holder-odd {
        @extend .tree-line-holder;
        background-color: var(--ace-neutral-100);
    }

    .tree-line-holder-even {
        @extend .tree-line-holder;
        background-color: white;
    }

    .node-collapsed:before {
        content: '\25B6';
        color: var(--ace-neutral-600);
    }

    .node-expanded, .node-collapsed {
        cursor: pointer;
        width: 14px;
        display: inline-block;
    }

    .node-expanded:before {
        content: '\25BC';
        color: var(--ace-neutral-600);
    }

    .node-expanded.empty-tree-node:before {
        content: '';
    }

    .node-collapsed.empty-tree-node:before {
        content: '';
    }
}

.tree-selector-path {
    color: var(ace-neutral-400);
    font-style: italic;
}
