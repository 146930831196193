@import '../compliance/compliance-variables.scss';

supplemental-information-sheet {
    .supplemental-page-row {
        margin-top: 8px;
        min-height: 50px;
    }

    .supplemental-grid-containing-row {
        flex: 1 1 auto;
        margin-bottom: 15px;
    }

    .supplemental-page-flex-column-left {
        flex: 0 0 250px;
        order: 1;
        padding-right: 8px;
    }

    .supplemental-page-flex-column-right {
        flex: 1 1 80%;
        order: 2;
        padding-left: 8px;
    }

    select {
        max-width: 200px;
    }

    .selectedfield, .unselectedfield {
        padding-left: 6px;
    }

    .selectedfield {
        background-color: none;
        color: var(--ace-neutral-700);
    }

    .unselectedfield {
        background-color: none;
        color: var(--ace-neutral-700);
    }

    .field-map-wrapper {
        box-shadow: 1px 1px 10px 1px var(--ace-neutral-300);
        padding: 10px;
    }

    supplemental-information-cell-renderer {

        .drop-down-on-hover-cell {
            width: 100%;
            min-height: 26px;

            .read-only-label {
                display: block;
            }

            div.edit-control {
                display: none;
            }
        }

        .drop-down-on-hover-cell.mouseover {
            .read-only-label {
                display: none;
            }

            .edit-control {
                display: block;
            }
        }
    }

    .value-inherited {
        color: var(ace-neutral-400);
        font-style: italic;
    }

    .value-override {
        color: var(--ace-danger);
    }

    .ag-floating-top-container .ag-row .ag-cell {
        background: var(--ace-neutral-100);
        font-weight: bold;
    }
}
