.ws-text-align-left {
    text-align: left;
}

.ws-text-align-right {
    text-align: right;
}

.ws-text-align-center {
    text-align: center;
}

.ws-vertical-align-middle {
    vertical-align: middle;
}

.ws-vertical-align-top {
    vertical-align: top;
}

.ws-display-block {
    display: block;
}

.ws-display-inline-block {
    display: inline-block;
}

.ws-uppercase {
    text-transform: uppercase;
}

.ws-stretch {
    width: 100%;
    height: 100%;

    &.ws-stretch-full {
        margin: 0;
        padding: 0;
    }
}

.ws-stretch-width {
    width: 100%;
}

.ws-stretch-height {
    height: 100%;
}

.ws-overflow-auto {
    overflow: auto;
}

.ws-overflow-hidden {
    overflow: hidden;
}

.ws-no-margin {
    margin: 0;
}

.ws-no-padding {
    padding: 0;
}

.ws-no-border {
    border: none;
}

.ws-position-relative {
    position: relative;
}

.ws-font-weight-normal {
    font-weight: normal;
}

.ws-font-weight-bold {
    font-weight: bold;
}

/** List **/
.ws-list-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

/** Table **/
.table-borderless td,
.table-borderless th {
    border: 0 !important;
}

.table-borderless-body td {
    border: 0 !important;
}

/** Flexbox Model **/
.ws-flex-none {
    flex: none;
}

.ws-flex-auto {
    flex: 1 1 auto;
}

.ws-flex-grow {
    display: flex;
    flex-grow: 1;
}

.ws-flex-container-horizontal {
    display: flex;
    flex-direction: row;
}

.ws-flex-container-horizontal-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ws-flex-container-vertical {
    display: flex;
    flex-direction: column;
}

.ws-flex-container-vertical-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.ws-flex-align-items-center {
    align-items: center;
}

.ws-flex-align-items-baseline {
    align-items: baseline;
}

.ws-flex-align-self-center {
    align-self: center;
}

.ws-flex-align-self-right {
    align-self: flex-end;
}

.ws-flex-justify-content-flex-start {
    justify-content: flex-start;
}

.ws-flex-justify-content-flex-end {
    justify-content: flex-end;
}

.ws-flex-justify-content-space-between {
    justify-content: space-between;
}

.ws-flex-justify-content-center {
    justify-content: center;
}

.ws-overflow-truncate {
    flex: 1;
    min-width: 0;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ws-truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ws-truncate-keep-spaces {
    min-width: 0;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ws-cursor-grab {
    cursor: grab;
}

.ws-cursor-grabbing {
    cursor: grabbing;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

// Helps align checkbox with label
.ws-checkbox-label {
    display: inline-block;
    white-space: nowrap;

    input {
        margin: 0 5px 0 0;
        vertical-align: middle;
    }

    span {
        vertical-align: middle;
    }
}

.warning-text {
    color: var(--ace-warning);
}

.w-fit-content {
    width: fit-content;
}

.grid-page {
    display: flex;
    flex-flow: column;
    width: 100%;
    flex: 1 0 0;
    overflow: hidden;
}
