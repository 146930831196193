.user-group-panels {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    height: 100%;
    padding-bottom: 30px;

    .panel-flat-color {
        height: 100%; 
    }
}

.user-group-panel-expanded {
    display: block;
}