@import "utilities/fonts.scss";

.document-processing-page {
    min-height: 50px;
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-content: space-around;
    section {
        &:first-of-type {
            margin-right: 7px;
        }
        &:last-child {
            margin-left: 7px;
        }
        margin: 5px 0;
        padding: 7px 0;
        border-radius: 0.375rem;
        flex: 1;
        min-width: 30px;
        background-color: var(--ace-neutral-white);
        box-shadow: 1px 1px var(--ace-neutral-100);
        &.resizable {
            flex: 0 0 500px;
        }
    }
    .document-processing-overlay {
        z-index: 1;
        display: none;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
    }
    .show-overlay .document-processing-overlay {
        display: block;
    }
    .document-processing-quick-search-panel {
        margin-bottom: 0;
        .document-processing-quick-search-header {
            margin-bottom: 0;
            .header-button-container {
                button {
                    margin-bottom: 5px;
                }
            }
        }
        .document-processing-quick-search-criteria, .document-processing-quick-search-results {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border: none;
            box-shadow: none;
        }
        .document-processing-quick-search-criteria {
            margin-bottom: 0;
            z-index: 2;
        }
        .document-processing-quick-search-results {
            min-width: 464px;
            position: relative;
            margin-bottom: 0;
            z-index: 1;
        }
    }
    .document-processing-entity-panel {
        .entity-panel-data {
            position: relative;
            td {
                padding: 2px 0;
                vertical-align: middle;
                &:first-child {
                    font-weight: bold;
                    white-space: nowrap;
                    padding-right: 10px;
                }
            }
            .projected {
                background-color: var(--not-actual);
            }
            .property-type-label {
                margin-top: 3px;
            }
            .overridden {
                .form-control, span {
                    color: var(--ace-danger) !important;
                }
            }
        }
    }
    .document-processing-preview-panel {}
    .document-processing-action-buttons {}
    .preview-window {
        width: 96.5%;
        margin-left: 25px;
        padding: 0;
        //height: 672px;
        //height: 80vh;
        .preview-window-image {
            max-height: 79vh;
            margin: auto;
        }
    }
    .download-preview-error {
        color: var(--ace-danger);
        margin: 3px 15px;
    }
    .action-buttons-row {
        padding-bottom: 60px;
        .document-type-select {
            width: 60%;
        }
        .inlineflex {
            display: inline-flex;
        }
        .btn {
            margin-top: 2px;
            float: right;
        }
        .row-label {
            margin-bottom: 14px;
            //width: 30%;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 5px;
            &.required {
                margin-bottom: 9px;
                margin-top: -5px;
                vertical-align: bottom;
            }
        }
    }
}

.document-processing-entity-edit-panel {
    .entity-edit-footer {
        margin-top:2em;
    }
}

.document-processing-exception-modal {
    label {
        display: block;
        font-weight: normal;
    }
    .row {
        margin: 0 24px;
    }
}

.action-footer {
    background-color: white;
    border-radius: 0.375rem;
    box-shadow: 1px 1px var(--ace-neutral-100);
}

.empty-document-page {
    text-align: center;
    width: 500px;
    margin: 30px auto 0 auto;
    height: 200px;
    background-color: white;
    border-radius: 8px;
    box-shadow: var(--ace-shadow);

	h3 {
		font-family: $header-font;
		font-size: 34px;
		padding-top: 30px;
		margin-bottom: 50px;
	}

	a:hover {
		text-decoration: none;
	}
}

.process-no-appeal-warranted {
    .radio {
        margin-left: 25px;
        input[type=radio] {
            margin-top: 2px;
        }
    }
}

.payment-package-table {
	th { padding: 0 8px; }
}

.editable-email-subject {
    display: inline-block;
    width: 745px;
}

.transmittal-details {
    .slide {
        background-color: white;
        border-left: 1px solid var(--ace-neutral-300);
        border-right: 1px solid var(--ace-neutral-300);
    }

    .carousel-control {
        width: 5%;

        .carousel-control-prev-icon, .carousel-control-next-icon {
            font-size: 100px;
            cursor: pointer;
        }
    }

    h1 {
		margin: 20px 0px 30px 0px;
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
	}

    .transmittal-address {
        height: 135px;
    }

    .transmittal-details-items {
        margin-top: 60px;
        height: 400px;
        overflow-y: auto;
    }

    .transmittal-data {
        margin-top: 24px;
        margin-left: 15px;
        td {
            &:first-child {
                font-weight: bold;
            }
            padding: 5px 10px;
            vertical-align: middle;
        }
    }
}

.invoice {
	padding-bottom: 50px;

	label {
		width: 112px;
		height: 27px;
	}

	row {
		padding: 20px 0px;
	}

	.fee-allocation {
		td {
			vertical-align: middle !important;

			input[text] {
				margin: -1px;
			}
		}
	}

	.invoice-table {
		.single-column {
			background-color: var(--ace-neutral-white);

			td {
				border-top: none;
			}

			.overline {
				border-top: solid 2px var(--ace-neutral-500) !important;
            }

		}
        .override-button {
            vertical-align: middle;
            .warning-button {
                margin: 0;
            }
        }
	}
}

.modal-create-invoice {
    label {
        display: block;
    }
}

.retrieval-status {
    .control-label {
        padding-top: 5px;
    }
}

.change-due-dates-date {
    width: 185px;
    .input-group {
        width: initial;
    }
}

sd-document-processing-search {
    width: 500px;
}
