
.finalize-check-import-grids {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .grid {
        height: 250px;
    }

    .row-matched {
        background: palegreen !important;
    }

    .ag-layout-normal .ag-floating-bottom {
        position: static;

        .ag-row {
            background: var(--ace-neutral-100);
        }

        .totals-row {
            .ag-cell.ag-cell-with-height.ag-cell-value:empty {
                background: var(--ace-neutral-100);

                &::after {
                    content: '-';
                }
            }
        }

        .totals-row {
            .ag-cell.ag-cell-with-height.ag-cell-value:not(:empty) {
                background: var(--ace-neutral-100);
            }
        }
    }
}
