form[name="vm.contactForm"] .title {
	display: inline-block;
	padding-top: 5px;
	margin-bottom: 5px;
	vertical-align: top;
	min-width: 90px;
	font-weight: normal;
}

form[name="vm.contactForm"] .editable {
	display: inline-block;
	padding-top: 5px;
	vertical-align: top;
}

.required {
	font-weight: bold;
}

.contact-entity-name {
	div {
		display: inline-block;
		vertical-align: top;
	}
}

.associate-contact-modal {
	.modal-dialog {
		height: 92%;
		.modal-content {
			overflow: auto;
			height: 95%;
		}
	}
}

.sites-permissions-dialog {
	.modal-dialog {
		height: 92%;
		.modal-content {
			overflow: auto;
			height: 95%;
		}
	}
}

.permission-dialog {
	.table-data {
		border: solid 1pt silver;
		border-radius: 8px;
		height: 400px;
		margin: 30px 0;
		overflow: auto;
		padding: 5px;
		table thead {
			background-color: var(--ace-neutral-300);
			border-top-left-radius: 8px;
			color: var(--ace-info);
			display: table;
			position: fixed;
			margin-left: -5px;
			margin-top: -5px;
			width: 90%;
            z-index: 2;
		}
		table tbody {
			display: table;
			margin-top: 30px;
			width: 100%;
		}
	}
	.search-button {
		cursor: pointer;
	}
}

sd-contact-info {
	div.popover-content {
		white-space: pre-wrap;
	}
}

.contact-company-picker {
	input[type=text] {
		width: 288px;
	}
}
