/***********************************************
* MIXINS
***********************************************/
@mixin icon-only-button {
	background-color: transparent;
	color: var(--ace-neutral-white);
	margin-right: 6px;
	font-size: 17px;
	transition: all .1s ease-in-out;
	box-shadow: none;

	&.dark-icon {
        --ace-action: #5373b4;
		color: var(--ace-action);
	}

	&:hover {
		transform: scale(1.2);
	}

    &.danger-icon {
        color: var(--ace-danger);

        &:hover {
            color: var(--ace-danger-hover);
        }
    }
}

@mixin multi-select {
	// width: 100%;
	// height: 150px;

	&:focus {
		outline: none;
	}

	option {
		padding-left: 5px;
	}

	option:checked {
		box-shadow: 0 0 10px 10px var(ace-info-ghosted) inset;
	}
}

@mixin la-ball-spin-css() {
    /*!
    * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
    * Copyright 2015 Daniel Cardoso <@DanielCardoso>
    * Licensed under MIT
    */
    .la-ball-spin-clockwise,
    .la-ball-spin-clockwise > div {
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    .la-ball-spin-clockwise {
        display: block;
        font-size: 0;
        color: var(--ace-neutral-white);
    }
    .la-ball-spin-clockwise.la-dark {
        color: var(--ace-neutral-800);
    }
    .la-ball-spin-clockwise > div {
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;
    }
    .la-ball-spin-clockwise {
        width: 32px;
        height: 32px;
    }
    .la-ball-spin-clockwise > div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        margin-left: -4px;
        border-radius: 100%;
        -webkit-animation: ball-spin-clockwise 1s infinite ease-in-out;
        -moz-animation: ball-spin-clockwise 1s infinite ease-in-out;
            -o-animation: ball-spin-clockwise 1s infinite ease-in-out;
                animation: ball-spin-clockwise 1s infinite ease-in-out;
    }
    .la-ball-spin-clockwise > div:nth-child(1) {
        top: 5%;
        left: 50%;
        -webkit-animation-delay: -.875s;
        -moz-animation-delay: -.875s;
            -o-animation-delay: -.875s;
                animation-delay: -.875s;
    }
    .la-ball-spin-clockwise > div:nth-child(2) {
        top: 18.1801948466%;
        left: 81.8198051534%;
        -webkit-animation-delay: -.75s;
        -moz-animation-delay: -.75s;
            -o-animation-delay: -.75s;
                animation-delay: -.75s;
    }
    .la-ball-spin-clockwise > div:nth-child(3) {
        top: 50%;
        left: 95%;
        -webkit-animation-delay: -.625s;
        -moz-animation-delay: -.625s;
            -o-animation-delay: -.625s;
                animation-delay: -.625s;
    }
    .la-ball-spin-clockwise > div:nth-child(4) {
        top: 81.8198051534%;
        left: 81.8198051534%;
        -webkit-animation-delay: -.5s;
        -moz-animation-delay: -.5s;
            -o-animation-delay: -.5s;
                animation-delay: -.5s;
    }
    .la-ball-spin-clockwise > div:nth-child(5) {
        top: 94.9999999966%;
        left: 50.0000000005%;
        -webkit-animation-delay: -.375s;
        -moz-animation-delay: -.375s;
            -o-animation-delay: -.375s;
                animation-delay: -.375s;
    }
    .la-ball-spin-clockwise > div:nth-child(6) {
        top: 81.8198046966%;
        left: 18.1801949248%;
        -webkit-animation-delay: -.25s;
        -moz-animation-delay: -.25s;
            -o-animation-delay: -.25s;
                animation-delay: -.25s;
    }
    .la-ball-spin-clockwise > div:nth-child(7) {
        top: 49.9999750815%;
        left: 5.0000051215%;
        -webkit-animation-delay: -.125s;
        -moz-animation-delay: -.125s;
            -o-animation-delay: -.125s;
                animation-delay: -.125s;
    }
    .la-ball-spin-clockwise > div:nth-child(8) {
        top: 18.179464974%;
        left: 18.1803700518%;
        -webkit-animation-delay: 0s;
        -moz-animation-delay: 0s;
            -o-animation-delay: 0s;
                animation-delay: 0s;
    }
    .la-ball-spin-clockwise.la-sm {
        width: 16px;
        height: 16px;
    }
    .la-ball-spin-clockwise.la-sm > div {
        width: 4px;
        height: 4px;
        margin-top: -2px;
        margin-left: -2px;
    }
    .la-ball-spin-clockwise.la-2x {
        width: 64px;
        height: 64px;
    }
    .la-ball-spin-clockwise.la-2x > div {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        margin-left: -8px;
    }
    .la-ball-spin-clockwise.la-3x {
        width: 96px;
        height: 96px;
    }
    .la-ball-spin-clockwise.la-3x > div {
        width: 24px;
        height: 24px;
        margin-top: -12px;
        margin-left: -12px;
    }
    .la-ball-spin-clockwise.la-oval > div {
        width: 2px;
        border-radius: 50%;

        &:nth-child(2) {
            rotate: 45deg;
        }

        &:nth-child(3) {
            rotate: 90deg;
        }

        &:nth-child(4) {
            rotate: 135deg;
        }

        &:nth-child(5) {
            rotate: 180deg;
        }

        &:nth-child(6) {
            rotate: 225deg;
        }

        &:nth-child(7) {
            rotate: 270deg;
        }

        &:nth-child(8) {
            rotate: 315deg;
        }
    }
    /*
    * Animation
    */
    @-webkit-keyframes ball-spin-clockwise {
        0%,
        100% {
            opacity: 1;
            -webkit-transform: scale(1);
                    transform: scale(1);
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 0;
            -webkit-transform: scale(0);
                    transform: scale(0);
        }
    }
    @-moz-keyframes ball-spin-clockwise {
        0%,
        100% {
            opacity: 1;
            -moz-transform: scale(1);
                transform: scale(1);
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 0;
            -moz-transform: scale(0);
                transform: scale(0);
        }
    }
    @-o-keyframes ball-spin-clockwise {
        0%,
        100% {
            opacity: 1;
            -o-transform: scale(1);
            transform: scale(1);
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 0;
            -o-transform: scale(0);
            transform: scale(0);
        }
    }
    @keyframes ball-spin-clockwise {
        0%,
        100% {
            opacity: 1;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
                -o-transform: scale(1);
                    transform: scale(1);
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 0;
            -webkit-transform: scale(0);
            -moz-transform: scale(0);
                -o-transform: scale(0);
                    transform: scale(0);
        }
    }
}

@mixin la-line-scale-css() {
    /*!
    * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
    * Copyright 2015 Daniel Cardoso <@DanielCardoso>
    * Licensed under MIT
    */
    .la-line-scale,
    .la-line-scale > div {
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .la-line-scale {
        display: block;
        font-size: 0;
        color: var(--ace-neutral-white);
    }

    .la-line-scale.la-dark {
        color: var(--ace-neutral-800);
    }

    .la-line-scale > div {
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;
    }

    .la-line-scale {
        width: 40px;
        height: 32px;
    }

    .la-line-scale > div {
        width: 4px;
        height: 32px;
        margin: 2px;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
        -webkit-animation: line-scale 1.2s infinite ease;
        -moz-animation: line-scale 1.2s infinite ease;
            -o-animation: line-scale 1.2s infinite ease;
                animation: line-scale 1.2s infinite ease;
    }

    .la-line-scale > div:nth-child(1) {
        -webkit-animation-delay: -1.2s;
        -moz-animation-delay: -1.2s;
            -o-animation-delay: -1.2s;
                animation-delay: -1.2s;
    }

    .la-line-scale > div:nth-child(2) {
        -webkit-animation-delay: -1.1s;
        -moz-animation-delay: -1.1s;
            -o-animation-delay: -1.1s;
                animation-delay: -1.1s;
    }

    .la-line-scale > div:nth-child(3) {
        -webkit-animation-delay: -1s;
        -moz-animation-delay: -1s;
            -o-animation-delay: -1s;
                animation-delay: -1s;
    }

    .la-line-scale > div:nth-child(4) {
        -webkit-animation-delay: -.9s;
        -moz-animation-delay: -.9s;
            -o-animation-delay: -.9s;
                animation-delay: -.9s;
    }

    .la-line-scale > div:nth-child(5) {
        -webkit-animation-delay: -.8s;
        -moz-animation-delay: -.8s;
            -o-animation-delay: -.8s;
                animation-delay: -.8s;
    }

    .la-line-scale.la-sm {
        width: 20px;
        height: 16px;
    }

    .la-line-scale.la-sm > div {
        width: 2px;
        height: 16px;
        margin: 1px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .la-line-scale.la-2x {
        width: 80px;
        height: 64px;
    }

    .la-line-scale.la-2x > div {
        width: 8px;
        height: 64px;
        margin: 4px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .la-line-scale.la-3x {
        width: 120px;
        height: 96px;
    }

    .la-line-scale.la-3x > div {
        width: 12px;
        height: 96px;
        margin: 6px;
        margin-top: 0;
        margin-bottom: 0;
    }

    /*
    * Animation
    */
    @-webkit-keyframes line-scale {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(.4);
                    transform: scaleY(.4);
        }
        20% {
            -webkit-transform: scaleY(1);
                    transform: scaleY(1);
        }
    }
    @-moz-keyframes line-scale {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(.4);
            -moz-transform: scaleY(.4);
                    transform: scaleY(.4);
        }
        20% {
            -webkit-transform: scaleY(1);
            -moz-transform: scaleY(1);
                    transform: scaleY(1);
        }
    }
    @-o-keyframes line-scale {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(.4);
                -o-transform: scaleY(.4);
                    transform: scaleY(.4);
        }
        20% {
            -webkit-transform: scaleY(1);
                -o-transform: scaleY(1);
                    transform: scaleY(1);
        }
    }
    @keyframes line-scale {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(.4);
            -moz-transform: scaleY(.4);
                -o-transform: scaleY(.4);
                    transform: scaleY(.4);
        }
        20% {
            -webkit-transform: scaleY(1);
            -moz-transform: scaleY(1);
                -o-transform: scaleY(1);
                    transform: scaleY(1);
        }
    }
}
