$page-top-padding: 8px;
$page-bottom-padding: 8px;

.stretch-flex-column {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}

.filter-row {
    margin: 0 0 8px 0;

    .filter-group {
        margin-right: 16px;

        label {
            margin-right: 8px;
        }
    }
}
