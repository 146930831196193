// Overrides built in ag-grid overlay that allows you to click through it
.ag-overlay ag-grid-loading-overlay {
    pointer-events: auto !important;
}

/* Factor Table */
.assessor-state, .factors-prior-year {
    color: var(--ace-neutral-500);
}

/* Forms */
.assessor-state {
    color: var(--ace-neutral-500);
}

.assessor-certified {
    color: var(--ace-neutral-500);
}

.address-disabled {
    cursor: unset !important;
}

.assessor-selected {
    font-weight: bold;
}

// These were in advanced-search.scss
// I don't believe they meant to have them be used app wide but it was never constrained
// and is now used as a default height for many grids
.ag-theme-balham {
    height: 70vh;
    width: 100%;
}

@media (max-height: 950px) {
    .ag-theme-balham {
        height: 69vh
    }
}

@media (max-height: 930px) {
    .ag-theme-balham {
        height: 65vh
    }
}

@media (max-height: 850px) {
    .ag-theme-balham {
        height: 60vh;
    }
}

@media (max-height: 750px) {
    .ag-theme-balham {
        height: 55vh
    }
}

@media (max-height: 650px) {
    .ag-theme-balham {
        height: 50vh
    }
}

.ag-popup {
    height: 0;
}

.ag-cell-wrapper {
    display: block !important;
    align-items: normal !important;
}

.ag-theme-balham .ag-row .ag-cell-data-changed {
    background-color: var(--ace-neutral-100) !important;
}

.ag-menu {
    overflow-y: visible !important;
}

.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none !important;
}

/**
HEADER GROUP COLORS
 */
.header-group-color-0 {
    background-color: #c3d2ff;
}
.header-group-color-1 {
    background-color: #ffcac3;
}
.header-group-color-2 {
    background-color: #d4ffc3;
}
.header-group-color-3 {
    background-color: #fff3c3;
}
.header-group-color-4 {
    background-color: #ebc3ff;
}
.header-group-color-5 {
    background-color: #c7c3ff;
}
.header-group-color-6 {
    background-color: #c3ffe8;
}
.header-group-color-7 {
    background-color: #ffdac3;
}
.header-group-color-8 {
    background-color: #ffc3f1;
}
.header-group-color-9 {
    background-color: #cbc3ff;
}
.header-group-color-10 {
    background-color: #c3ffea;
}
.header-group-color-11 {
    background-color: #fff5c3;
}
.header-group-color-12 {
    background-color: #ffcec3;
}

